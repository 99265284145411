import { Injectable } from '@angular/core';
import { Routes } from '../../config/routes';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Hit
{
    id: number;
    index: 'units' | 'members';
    organizationId: number;
}

@Injectable({
    providedIn: 'root'
})
export class ElasticSearchService
{
    searchString: string = '';
    searchResults: BehaviorSubject<Hit[]> = new BehaviorSubject<Hit[]>([]);

    constructor(
            private _http: ApplicationHttpClient,
    ) {
    }

    /**
     * Handle the Algolia search
     * @param searchString
     * @param limit
     */
    public search(searchString, limit): Observable<Hit[]> {
        const url = Routes.MakeLegFiCoreUrl('/search') + this._http.queryStringFromObject({
            term: searchString,
            limit: limit,
        });
        this.searchString = searchString;
        return this._http.get(url).pipe(map((response: Hit[]) => {
            this.searchResults.next(response);
            return response;
        }));
    }
}
