import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    // root navigation will route to login
    { path: '', pathMatch: 'full', redirectTo: 'auth/login' },

    {
        path: 'auth',
        loadChildren: () => import('./components/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
    },
    {
        path: 'app',
        loadChildren: () => import('./components/app-layout/app-layout.module.payhoa').then(m => m.AppLayoutModulePayhoa),
    },
    {
        path: 'events',
        loadChildren: () => import('./components/app-layout/app-layout.module').then(m => m.AppLayoutModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./components/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
    },
    {
        path: 'redirect',
        loadChildren: () => import('./components/redirect/redirect.module').then(m => m.RedirectModule),
    },
    {
        path: 'error',
        loadChildren: () => import('./components/error/error.module').then(m => m.ErrorModule),
    },

    // Catch any unmatched routes and redirect to the error path
    { path: '**', redirectTo: 'error' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule
{ }
