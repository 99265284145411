export class TransactionRuleAction
{
    id: number;
    transactionType: string;
    typeId: number;
    memo: string;

    constructor(req: any) {
        this.id = req.id;
        this.transactionType = req.transactionType;
        this.typeId = req.typeId;
        this.memo = req.memo;
    }
}
