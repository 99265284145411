import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PrintService
{
    public printElement(windowTitle, elementId) {
        const printContents = document.getElementById(elementId).innerHTML;
        const stylesHtml = this.getTagsHtml('style');
        const linksHtml = this.getTagsHtml('link').replace('href="styles.css"', 'href="' + window.origin + '/styles.css"');

        const popupWin = window.open('', '_blank', 'top=0,left=0,height=600,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
    <html>
        <head>
            <title>${windowTitle}</title>
            ${linksHtml}
            ${stylesHtml}
        </head>
        <body onload="window.print(); window.close()">
            ${printContents}
        </body>
    </html>
    `,
        );
        popupWin.document.close();
    }

    private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
        const htmlStr: string[] = [];
        const elements = document.getElementsByTagName(tagName);
        for (let idx = 0; idx < elements.length; idx++) {
            htmlStr.push(elements[idx].outerHTML);
        }

        return htmlStr.join('\r\n');
    }

}
