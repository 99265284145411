/**
 * Class to render details of one question, which exists within a form.
 */
import { FormQuestion } from './form-question';
import { OrganizationFile } from '../organization-file';
import { FormApprover } from './form-approver';
import { FormSubmission } from './form-submission';
import moment from 'moment-timezone';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';


export class Form
{
    /** Id from the Form table */
    id?: number;
    /** Org id of the person who created the form */
    organizationId?: number;
    /** Membership id of the person who created the form */
    membershipId: number;
    /** Name of the form; required */
    name: string;
    /** Description of the form; optional */
    description ?: string;
    /** Description of the optional document upload; optional */
    fileDescription ?: string;
    /** Id of Org Doc of optional upload */
    orgFileId ?: number;
    /** Model/class tying to db models; just 'inquiry' for now, may scale later */
    category: '';
    /** String of the image url that they chose for the icon; just choosing some default for now */
    iconImage: '';
    /** Whether the form is enabled/active or not */
    isEnabled ? = false;
    /** Whether the form is public or private */
    isPrivate ? = false;
    /** Array of one or more questions that comprise the form */
    questions: FormQuestion[] = [];
    /** Optional org file they may have added on create of form */
    orgFile: OrganizationFile;
    /** Bool if form is clicked on screen to show active state */
    clicked ? = false;
    /** Num pending subs */
    numPending: number;
    /** Num complete subs */
    numComplete: number;
    /** Num complete approvers */
    numApprovers: number;
    /** Most Recent submission date for form */
    lastSubmittedDate: moment.Moment;
    /** If the form uses the approval workflow or not. */
    usesApprovals = false;
    /** Whether they want approvers to receive emails on create of form submission or not. */
    hasManualApprovalNotifications = false;
    /** Optional array of form approvers */
    approvers: FormApprover[] = [];
    submissions: FormSubmission[] = [];

    canUserRead = false;
    canUserWrite = false;
    canUserWriteStatuses = false;
    isUserApprover = false;

    constructor(request: any) {
        this.id = request.id || 0;
        this.organizationId = request.organizationId || 0;
        this.membershipId = request.membershipId || 0;
        this.name = request.name || '';
        this.description = request.description || '';
        this.fileDescription = request.fileDescription || '';
        this.orgFileId = request.orgFileId || null;
        this.category = request.category || '';
        this.iconImage = request.iconImage || '';
        this.isEnabled = request.isEnabled === 1 || false;
        this.isPrivate = request.isPrivate === 1 || false;
        this.numPending = request.numPending || 0;
        this.numComplete = request.numComplete || 0;
        this.numApprovers = request.numApprovers || 0;
        this.usesApprovals = request.usesApprovals;
        this.canUserRead = request.canUserRead;
        this.canUserWrite = request.canUserWrite;
        this.canUserWriteStatuses = request.canUserWriteStatuses;
        this.isUserApprover = request.isUserApprover;
        this.hasManualApprovalNotifications = request.hasManualApprovalNotifications;


        if (request.questions && request.questions.length) {
            this.questions = request.questions.map(o => new FormQuestion(o));
        }

        if (request.orgFile) {
            this.orgFile = new OrganizationFile(request.orgFile);
        }

        const timezone = LegFiJwtService.getTimezone();

        if (request.lastSubmittedDate) {
            this.lastSubmittedDate = moment.utc(request.lastSubmittedDate, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        this.usesApprovals = false;
        if (request.approvers && request.approvers.length) {
            this.approvers = request.approvers.map(a => new FormApprover(a));
        }
        if ((request.approvers && request.approvers.length) || this.numApprovers > 0) {
            this.usesApprovals = true;
        }

        if (request.submissions) {
            this.submissions = request.submissions.map(s => {
                const sub = new FormSubmission(s);
                sub.form.canUserRead = request.canUserRead;
                sub.form.canUserWrite = request.canUserWrite;
                sub.form.isUserApprover = request.isUserApprover;

                return sub;
            });
        }
    }
}
