import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LegFiJwtService } from './services/auth/legfi-jwt.service';
import { GrowlerService } from './services/growler.service';
import { SvgIconService } from './services/svg-icon.service';
import { HostListenerService } from './services/host-listener.service';
import { GtmService } from './services/gtm.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointSizes } from './enums/breakpoint-sizes.enum';
import { IdleTimeoutService } from './services/idle-timeout.service';

@Component({
    selector: 'lf-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit
{
    private previousRoute: string;

    constructor(
            private _router: Router,
            private _svg: SvgIconService,
            private _host: HostListenerService,
            private _idle: IdleTimeoutService,
            private _gtm: GtmService,
            private _dialog: MatDialog,
            private _growler: GrowlerService,
            private _observer: BreakpointObserver,
    ) {
        this._svg.registerAppMatIcons();
    }

    @HostListener('document:click', ['$event']) onAppClick(event: MouseEvent) {
        this._host.documentClickedEvent$.next(event);
    }

    @HostListener('window:keydown', ['$event']) onAppKeyDownEvent(event: KeyboardEvent) {
        this._host.windowKeydownEvent$.next(event);
    }

    @HostListener('window:keyup', ['$event']) onAppKeyUpEvent(event: KeyboardEvent) {
        this._host.windowKeyupEvent$.next(event);
    }

    @HostListener('window:scroll', ['$event']) onAppScrollEvent() {
        this._host.windowScrollEvent$.next();
    }

    @HostListener('document:visibilitychange', ['$event']) onAppVisibilityEvent() {
        if (!document.hidden) {
            this.handleTabVisibilityChange();
        }
    }

    ngOnInit() {
        if (LegFiJwtService.read() !== null) {
            this._idle.watch();
        }

        this.handleLoginRouteIssues();
        this.handleBreakpointObservers();
    }

    private handleLoginRouteIssues() {
        this.previousRoute = '/';
        this._router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                const url = event.url;

                if (url.indexOf('auth/login;nonce') > -1) {
                    const arr = url.split(';');
                    const nonce = arr[1].replace('nonce=', '');
                    const orgId = arr[2].replace('orgId=', '');
                    let uri = '';
                    if (arr.length > 3) {
                        uri = decodeURIComponent(arr[3].replace('uri=', ''));
                    }
                    this._router.navigate(['/auth/login/' + nonce + '/' + orgId + '/' + encodeURIComponent(uri)]);
                } else if (url.indexOf('auth/login;uri=/') > -1) {
                    const arr = url.split(';');
                    const uri = decodeURIComponent(arr[1].replace('uri=', ''));
                    this._router.navigate([
                        '/auth/login',
                        {uri: uri},
                    ]);
                } else if (url.indexOf('auth/login;uri=') > -1 && url.indexOf('sign-up') > -1) {
                    // TODO: determine true culprit and add exception
                    // On login fail from branded login page, app is redirecting to /auth/login;uri=%252Fauth%252Fsign-up%252ForgId
                    const arr = url.split('%252F');
                    this._router.navigate([
                        `/auth/sign-up/${arr[2]}`
                    ]);
                }
            }

            if (event instanceof NavigationEnd) {
                this._gtm.pageView({
                    pageName: event.url,
                    previousRoute: this.previousRoute,
                });

                // Update to current route
                this.previousRoute = event.url;
            }
        });
    }

    private handleTabVisibilityChange() {
        const isLoggedIn: boolean = LegFiJwtService.read() !== null;
        if (!isLoggedIn && (this._router.url.indexOf('/app/') > -1 || this._router.url.indexOf('/admin/') > -1)) {
            this._dialog.closeAll(); // only handles mat-dialogs...
            this._growler.error('Signed Out', 'You have been logged out of the app. Please, sign in again to continue.');

            const uri = encodeURIComponent(this.previousRoute.replace('uri=', ''));
            this._router.navigate(['/auth/login'], {queryParams: { uri }});
        }
    }

    private handleBreakpointObservers() {
        this._observer.observe([BreakpointSizes.SM.observer]).subscribe((res) => {
            this._host.smBreakpoint$.next(res.matches);
        });
        this._observer.observe([BreakpointSizes.MD.observer]).subscribe((res) => {
            this._host.mdBreakpoint$.next(res.matches);
        });
    }
}
