import { Observable, throwError, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { catchError, map } from 'rxjs/operators';
import { Routes } from '../../../config/routes';
import { Location } from '@angular/common';
import { ApplicationHttpClient } from './application-http-client';
import { IdleTimeoutService } from '../../../services/idle-timeout.service';
import { AuthService } from '../../../services/auth/auth.service';
import moment from 'moment-timezone';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor
{
    protected refreshIntervalHandle = null;

    constructor(
            private _http: ApplicationHttpClient,
            private _location: Location,
            private _authService: AuthService,
            private _idle: IdleTimeoutService,
    ) {
    }

    public intercept(
            req: HttpRequest<any>,
            next: HttpHandler,
    ): Observable<HttpEvent<any>> {

        let headers = req.headers;

        const dontAddList = [
            '.plaid.com',
        ];

        for (const toMatch of dontAddList) {
            if (req.url.indexOf(toMatch) !== -1) {
                return next.handle(req);
            }
        }

        const jwt = LegFiJwtService.raw();
        if (jwt !== null) {
            headers = headers.set('Authorization', 'Bearer ' + jwt);
        }

        const clonedRequest: HttpRequest<any> = req.clone({
            headers: headers,
        });

        return next.handle(clonedRequest)
                .pipe(catchError((error) => {
                            if (error.status === 401 && LegFiJwtService.raw()) {
                                LegFiJwtService.clear();
                            }

                            const currentPage: string = this._location.path();
                            if (
                                    error.status === 401 &&
                                    currentPage.indexOf('/auth/login') === -1 &&
                                    currentPage.indexOf('/auth/payment-login') === -1 &&
                                    error.url.indexOf('plaid') === -1
                            ) {
                                return this.redirectAndThrow401Observable();
                            } else {
                                return observableThrowError(error);
                            }
                        }),
                        map((response: HttpResponse<any>) => {
                            this._idle.reset();

                            if (response.headers && response.headers.has('X-LegFiRefresh')) {
                                LegFiJwtService.store(
                                        response.headers.get('X-LegFiRefresh'),
                                );

                                const payload: any = LegFiJwtService.decodeToken(response.headers.get('X-LegFiRefresh'));
                                const now = Math.round((new Date().getTime()) / 1000);
                                const expires: number = payload['exp'];
                                const refreshAfter = (expires - now - 120) * 1000;

                                if (payload['legfi'].hasOwnProperty('orgTimezone')) {
                                    moment.tz.setDefault(payload['legfi']['orgTimezone']);
                                }

                                // 2 minutes before the JWT expires, attempt to refresh it
                                // Any refreshes from navigating around will reset this timeout
                                if (this.refreshIntervalHandle) {
                                    clearTimeout(this.refreshIntervalHandle);
                                }
                                this.refreshIntervalHandle = setTimeout(() => {
                                    this._http.get(
                                            Routes.MakeLegFiCoreUrl('/keep-alive'),
                                    ).subscribe(() => {
                                        console.log('Auth token refreshed automatically');
                                    }, () => {
                                        console.log('Auth token failed to refresh automatically');
                                    });
                                }, refreshAfter);
                            }
                            return response;
                        }),
                );
    }

    public redirectAndThrow401Observable() {
        this._authService.logout(true);

        return throwError(() => new HttpErrorResponse({
            error: JSON.stringify({
                errors: [
                    {
                        msg: 'You must be logged in to perform this action.',
                        dev: 'No JWT available.',
                    },
                ],
            }),
            status: 401,
            statusText: 'Unauthorized',
        }));
    }
}
