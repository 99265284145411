import { NgModule } from '@angular/core';
import { AuditHistoryComponent } from './audit-history.component';
import { SharedModule } from '../../../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AdminService } from '../../../../services/admin/admin.service';
import { LegfiDatePipeModule } from '../../../../pipes/format/legfi-date.module';
import { LegfiJsonPipeModule } from '../../../../pipes/format/legfi-json.module';
import { LegfiJsonPipe } from '../../../../pipes/format/legfi-json.pipe';
import { LegfiDatePipe } from '../../../../pipes/format/legfi-date.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    imports: [
        SharedModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        LegfiDatePipeModule,
        LegfiJsonPipeModule,
        MatSelectModule,
        MatExpansionModule,
    ],
    exports: [AuditHistoryComponent],
    declarations: [AuditHistoryComponent],
    providers: [
        AdminService,
        LegfiDatePipe,
        LegfiJsonPipe,
    ],
})
export class AuditHistoryModule
{
}
