/**
 * A single stored answer to a custom form question; lives on form submission.
 */
import { FormQuestion } from './form-question';

export class FormAnswer
{
    id: number;
    formSubmissionId: number;
    formQuestionId: number;
    answer: string;
    question: FormQuestion;

    constructor(request: any) {
        this.id = request.id;
        this.formSubmissionId = request.formSubmissionId;
        this.formQuestionId = request.formQuestionId;
        this.answer = request.answer;

        if (request.question) {
            this.question = new FormQuestion(request.question);
        }
    }
}
