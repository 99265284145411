import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { PaymentGroup } from './payment-group';
import { PaymentItem } from './payment-item';
import { DepositBankAccount } from './deposit-bank-account';
import { PaymentAttemptFee } from './payment-attempt-fee';
import { Transaction } from './transactions/transaction';
import { TransactionPaymentAttempt } from './transaction-payment-attempt';
import { Charge } from 'app/models/entities/charge';
import Moment = moment.Moment;

export class PaymentAttempt
{
    id: number;
    paymentGroupId: number;
    depositBankAccountId: number;
    processor: string;
    processorTransactionId: string;
    confirmationNumber: string;
    net: number;
    applied: number;
    gross: number;
    currency: string;
    absorbFees: boolean;
    returnReason: string;
    returned: boolean;
    pending: boolean;
    success: boolean;
    autopaid: boolean;
    fundsAvailable: string;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    importedAt: Moment;

    // Possible appended / joined values
    givenNames: string;
    familyName: string;
    escrow: number;
    absorbedFees: number;
    offloadedFees: number;

    depositBankAccount: DepositBankAccount;
    fundingAttempt: PaymentAttempt;
    paymentGroup: PaymentGroup;
    fundingAttempts: PaymentAttempt[];
    paymentAttemptFees: PaymentAttemptFee[];
    paymentItems: PaymentItem[];
    transactions: Transaction[];
    transactionsCount: number;
    transactionToPaymentAttempts: TransactionPaymentAttempt[];

    // When true, Charge is meant to be added to DOM but not rendered.
    hidden: boolean;
    checked: boolean;
    showDetails: boolean;
    categoryString: string;
    response: any;

    constructor(paymentAttempt: any) {
        if (paymentAttempt.id) {
            this.id = paymentAttempt.id;
        }
        if (paymentAttempt.paymentGroupId) {
            this.paymentGroupId = paymentAttempt.paymentGroupId;
        }
        if (paymentAttempt.depositBankAccountId) {
            this.depositBankAccountId = paymentAttempt.depositBankAccountId;
        }
        if (paymentAttempt.processor) {
            this.processor = paymentAttempt.processor;
        }
        if (paymentAttempt.processorTransactionId) {
            this.processorTransactionId = paymentAttempt.processorTransactionId;
        }
        if (paymentAttempt.confirmationNumber) {
            this.confirmationNumber = paymentAttempt.confirmationNumber;
        }
        if (paymentAttempt.currency) {
            this.currency = paymentAttempt.currency.toLocaleUpperCase();
        }

        if (paymentAttempt.net) {
            this.net = paymentAttempt.net;
        } else if (paymentAttempt.net === 0) {
            this.net = 0;
        }
        if (paymentAttempt.applied) {
            this.applied = paymentAttempt.applied;
        } else if (paymentAttempt.applied === 0) {
            this.applied = 0;
        }
        if (paymentAttempt.gross) {
            this.gross = paymentAttempt.gross;
        } else if (paymentAttempt.gross === 0) {
            this.gross = 0;
        }

        if (paymentAttempt.currency) {
            this.currency = paymentAttempt.currency;
        }
        if (paymentAttempt.absorbFees) {
            this.absorbFees = paymentAttempt.absorbFees;
        }
        if (paymentAttempt.returnReason) {
            this.returnReason = paymentAttempt.returnReason;
        }
        // truthy/falsy values should inherit their original value
        this.returned = paymentAttempt.returned;
        this.pending = paymentAttempt.pending;
        this.success = paymentAttempt.success;
        this.autopaid = paymentAttempt.autopaid;

        if (paymentAttempt.fundsAvailable) {
            this.fundsAvailable = paymentAttempt.fundsAvailable;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (paymentAttempt.createdAt) {
            this.createdAt = moment.utc(paymentAttempt.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentAttempt.updatedAt) {
            this.updatedAt = moment.utc(paymentAttempt.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentAttempt.deletedAt) {
            this.deletedAt = moment.utc(paymentAttempt.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentAttempt.importedAt) {
            this.importedAt = moment.utc(paymentAttempt.importedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (paymentAttempt.givenNames) {
            this.givenNames = paymentAttempt.givenNames;
        }
        if (paymentAttempt.familyName) {
            this.familyName = paymentAttempt.familyName;
        }

        if (paymentAttempt.escrow) {
            this.escrow = paymentAttempt.escrow;
        } else if (paymentAttempt.escrow === 0) {
            this.escrow = 0;
        }
        if (paymentAttempt.absorbedFees) {
            this.absorbedFees = paymentAttempt.absorbedFees;
        } else if (paymentAttempt.absorbedFees === 0) {
            this.absorbedFees = 0;
        }
        if (paymentAttempt.offloadedFees) {
            this.offloadedFees = paymentAttempt.offloadedFees;
        } else if (paymentAttempt.offloadedFees === 0) {
            this.offloadedFees = 0;
        }

        if (paymentAttempt.depositBankAccount) {
            this.depositBankAccount = new DepositBankAccount(paymentAttempt.depositBankAccount);
        }
        if (paymentAttempt.fundingAttempt) {
            this.fundingAttempt = new PaymentAttempt(paymentAttempt.fundingAttempt);
        }
        if (paymentAttempt.paymentGroup) {
            this.paymentGroup = new PaymentGroup(paymentAttempt.paymentGroup);
        }
        if (paymentAttempt.fundingAttempt) {
            this.fundingAttempt = new PaymentAttempt(paymentAttempt.fundingAttempt);
        }
        if (paymentAttempt.fundingAttempts) {
            this.fundingAttempts = paymentAttempt.fundingAttempts.map(
                    (fundingAttempt) => {
                        return new PaymentAttempt(fundingAttempt);
                    },
            );
        }
        if (paymentAttempt.paymentAttemptFees) {
            this.paymentAttemptFees = paymentAttempt.paymentAttemptFees.map(
                    (paymentAttemptFee) => {
                        return new PaymentAttemptFee(paymentAttemptFee);
                    },
            );
        }
        if (paymentAttempt.paymentItems) {
            this.paymentItems = paymentAttempt.paymentItems.map(
                    (paymentItem) => {
                        return new PaymentItem(paymentItem);
                    },
            );

            let cats = [];
            for (const item of this.paymentItems) {
                if (item.payable && item.payableType.indexOf('Charge') !== -1) {
                    const charge = <Charge>item.payable;
                    if (charge.category && charge.category.name) {
                        cats.push(charge.category.name);
                    }
                }
            }
            cats = cats.filter((v, i) => cats.indexOf(v) === i);
            if (cats.length === 1) {
                this.categoryString = cats[0];
            } else if (cats.length > 1) {
                this.categoryString = cats.length + ' Categories';
            } else {
                this.categoryString = '--';
            }
        }
        if (paymentAttempt.transactions) {
            this.transactions = paymentAttempt.transactions.map(
                    (transaction) => {
                        return new Transaction(transaction);
                    },
            );
        }
        this.transactionsCount = paymentAttempt.transactionsCount || this.transactions?.length || null;
        if (paymentAttempt.transactionToPaymentAttempts) {
            this.transactionToPaymentAttempts = paymentAttempt.transactionToPaymentAttempts.map(
                    (transactionPaymentAttempt) => {
                        return new TransactionPaymentAttempt(transactionPaymentAttempt);
                    },
            );
        }
        if (paymentAttempt.response) {
            this.response = paymentAttempt.response;
        }
    }
}
