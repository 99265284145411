import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import { environment } from 'environments/environment';
import * as client from 'pusher-js';
import { LegFiJwtService } from './auth/legfi-jwt.service';

// @ts-ignore
window.Pusher = client;

@Injectable({
    providedIn: 'root',
})
export class EchoService
{
    private _echo;

    public channel(channel: string) {
        return this.getEchoInstance().channel(channel);
    }

    public private(channel: string) {
        return this.getEchoInstance().private(channel);
    }

    public leave(channel: string) {
        return this.getEchoInstance().leave(channel);
    }

    public leaveChannel(channel: string) {
        return this.getEchoInstance().leaveChannel(channel);
    }

    public getSocketId() {
        if (this._echo && this._echo.socketId()) {
            return this._echo.socketId();
        }
        return null;
    }

    private getEchoInstance() {
        // TODO - fix this so it works on org change
        // if(!this._echo) {
        this._echo = new Echo({
            broadcaster: 'pusher',
            key: environment.PusherAppKey,
            cluster: environment.PusherAppCluster,
            forceTLS: true,
            host: environment.LegFiCoreApi,
            authEndpoint: environment.LegFiCoreApi + '/broadcasting/auth',
            auth: {
                headers: {
                    Authorization: 'Bearer ' + LegFiJwtService.raw(),
                    Accept: 'application/json',
                    'X-Legfi-Site-Id': environment.SiteId.toString(),
                },
            },
        });
        // }
        return this._echo;
    }
}
