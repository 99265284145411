export class TransactionRuleAccount
{
    id: number;
    accountId: number;

    constructor(req: any) {
        this.id = req.id;
        this.accountId = req.accountId;
    }
}
