import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Membership } from './membership';
import { PaymentAttempt } from './payment-attempt';
import { PaymentGroup } from './payment-group';
import Moment = moment.Moment;

export class AccountCredit
{
    id: number;
    creatorId: number;
    creatorType: string;
    ownerId: number;
    ownerType: string;
    amount: number;
    currency: string;
    used: boolean;
    remaining: number;

    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;

    creator: PaymentAttempt;
    owner: Membership;
    paymentGroups: PaymentGroup[] = [];

    constructor(accountCredit: any) {
        if (accountCredit.id) {
            this.id = accountCredit.id;
        }
        if (accountCredit.creatorId) {
            this.creatorId = accountCredit.creatorId;
        }
        if (accountCredit.creatorType) {
            this.creatorType = accountCredit.creatorType;
        }
        if (accountCredit.ownerId) {
            this.ownerId = accountCredit.ownerId;
        }
        if (accountCredit.ownerType) {
            this.ownerType = accountCredit.ownerType;
        }
        if (accountCredit.amount) {
            this.amount = accountCredit.amount;
        }
        if (accountCredit.currency) {
            this.currency = accountCredit.currency.toLocaleUpperCase();
        }
        if (accountCredit.used) {
            this.used = accountCredit.used;
        }
        if (accountCredit.hasOwnProperty('remaining')) {
            this.remaining = accountCredit.remaining;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (accountCredit.createdAt) {
            this.createdAt = moment.utc(accountCredit.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (accountCredit.updatedAt) {
            this.updatedAt = moment.utc(accountCredit.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (accountCredit.deletedAt) {
            this.deletedAt = moment.utc(accountCredit.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (accountCredit.creator && accountCredit.creatorType !== null) {
            if (accountCredit.creatorType.indexOf('PaymentAttempt') !== -1) {
                this.creator = new PaymentAttempt(accountCredit.creator);
            }
        }
        if (accountCredit.owner && accountCredit.ownerType !== null) {
            if (accountCredit.ownerType.indexOf('Membership') !== -1) {
                this.owner = new Membership(accountCredit.owner);
            }
        }
        if (accountCredit.paymentGroups) {
            this.paymentGroups = accountCredit.paymentGroups.map((g) => new PaymentGroup(g));
        }
    }
}
