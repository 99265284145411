import moment, { Moment } from 'moment-timezone';
import { FundingBatch } from './funding-batch';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Organization } from './organization';
import { PaymentAttempt } from './payment-attempt';

export class FundingAttempt
{
    id: number;
    organizationId: number;
    fundingPaymentAttemptId: number;
    fundingBatchId: number;
    paymentAttemptId: number;
    paid: boolean;
    createdAt: Moment;
    updatedAt: Moment;

    fundingTotal: number;

    fundingBatch: FundingBatch;
    fundingPaymentAttempt: PaymentAttempt;
    organization: Organization;
    paymentAttempt: PaymentAttempt;

    constructor(fundingAttempt: any) {
        if (fundingAttempt.id) {
            this.id = fundingAttempt.id;
        }
        if (fundingAttempt.organizationId) {
            this.organizationId = fundingAttempt.organizationId;
        }
        if (fundingAttempt.fundingPaymentAttemptId) {
            this.fundingPaymentAttemptId = fundingAttempt.fundingPaymentAttemptId;
        }
        if (fundingAttempt.fundingBatchId) {
            this.fundingBatchId = fundingAttempt.fundingBatchId;
        }
        if (fundingAttempt.paymentAttemptId) {
            this.paymentAttemptId = fundingAttempt.paymentAttemptId;
        }
        if (fundingAttempt.paid) {
            this.paid = fundingAttempt.paid;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (fundingAttempt.createdAt) {
            this.createdAt = moment.utc(fundingAttempt.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (fundingAttempt.updatedAt) {
            this.updatedAt = moment.utc(fundingAttempt.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (fundingAttempt.fundingTotal) {
            this.fundingTotal = fundingAttempt.fundingTotal;
        }

        if (fundingAttempt.fundingBatch) {
            this.fundingBatch = new FundingBatch(fundingAttempt.fundingBatch);
        }
        if (fundingAttempt.fundingPaymentAttempt) {
            this.fundingPaymentAttempt = new PaymentAttempt(fundingAttempt.fundingPaymentAttempt);
        }
        if (fundingAttempt.organization) {
            this.organization = new Organization(fundingAttempt.organization);
        }
        if (fundingAttempt.paymentAttempt) {
            this.paymentAttempt = new PaymentAttempt(fundingAttempt.paymentAttempt);
        }
    }
}
