import { LegFiJwtService } from '../../../services/auth/legfi-jwt.service';
import { Membership } from '../membership';
import { FormAnswer } from './form-answer';
import { Owner } from '../owner';
import { Unit } from '../unit';
import { Tag } from '../tag';
import { FormSubmissionComment } from './form-submission-comment';
import { Form } from './form';
import moment from 'moment-timezone';
import { FormSubmissionApproval } from './form-submission-approval';

export class FormSubmission
{
    id: number;
    formId: number;
    membershipId: number;
    membership: Membership;
    unitId: number;
    unit: Unit;
    answers: FormAnswer[] = [];
    comments: FormSubmissionComment[] = [];
    status: string;
    formSubmissionStatusId: number;
    tags: Tag[] = [];
    completionDate: moment.Moment;
    createdAt: moment.Moment;
    ifShowApprovalsBasedOnDate: boolean;
    hasMemberApprovedSubmission: boolean;
    form: Form;
    approvals: FormSubmissionApproval[];

    approvalsGiven = 0;

    constructor(request: any) {
        this.id = request.id;
        this.membershipId = request.membershipId;
        this.formId = request.formId;
        this.unitId = request.unitId;

        const timezone = LegFiJwtService.getTimezone();

        this.createdAt = moment.utc(request.createdAt).tz(timezone);
        this.completionDate = request.completionDate ? moment.utc(request.completionDate).tz(timezone) : null;

        // approval workflow didn't exist before this date so data wasn't collected
        this.ifShowApprovalsBasedOnDate = this.createdAt.isSameOrAfter(moment('2021-07-08'));

        // putting this in for now until we fix it on model saves...
        if (request.status && (request.status === 'complete' || request.status === 'pending')) {
            this.status = 'No Status';
        } else {
            this.status = request.status || 'No Status';
        }

        this.formSubmissionStatusId = request.formSubmissionStatusId;

        if (request.answers) {
            this.answers = request.answers.map(a => new FormAnswer(a));
        }

        if (request.tags) {
            this.tags = request.tags.map(a => new Tag(a));
        }

        if (request.comments) {
            this.comments = request.comments.map((a) => new FormSubmissionComment(a));
        }

        if (request.unit) {
            this.unit = new Unit(request.unit);
        }

        if (request.membership) {
            this.membership = new Membership(request.membership);
        }

        if (request.approvals) {
            this.approvals = request.approvals.map((a) => new FormSubmissionApproval(a));
        }

        if (request.approvalsGiven) {
            this.approvalsGiven = request.approvalsGiven || 0;
        }

        this.hasMemberApprovedSubmission = request.hasMemberApprovedSubmission;

        this.form = request.form ? new Form(request.form) : null;
    }
}

/** Class to extend form submission with some searchable, filterable fields */
export class FormSubmissionFlat extends FormSubmission
{
    submittedDate: moment.Moment;
    completionDate: moment.Moment;
    unitName: string;
    unitTags: Tag[];
    ownerName: string;
    status: string;
    tagString: string;
    form: Form;
    formattedAnswers: { questionText: string; answerText: string }[];
    formAnswerString: string;

    constructor(request: any) {
        super(request);

        this.submittedDate = request.createdAt;
        this.completionDate = request.completionDate || null;

        // putting this in for now until we fix it on model saves...
        if (request.status && (request.status === 'complete' || request.status === 'pending')) {
            this.status = 'No Status';
        } else {
            this.status = request.status || 'No Status';
        }

        if (request.unit) {
            const unit = new Unit(request.unit);
            this.unitName = unit.name;
            this.unitTags = unit.tags || [];

            if (unit.owners && unit.owners.length) {
                this.ownerName = unit.owners.map((o: Owner) => o.name).toString().toLocaleLowerCase();
            }
        }

        if (request.form) {
            this.form = request.form;
        }

        if (request.tags) {
            const tags = request.tags.map((a) => new Tag(a));
            this.tagString = tags.map((t: Tag) => t.tag).toString().toLocaleLowerCase();
        }

        if (request.answers) {
            this.formattedAnswers = request.answers.map((a) => {
                const answer = new FormAnswer(a);
                let answerValue = answer.answer;

                const q = answer.question;
                if (q.type === 'hr' || q.type === 'plaintext') {
                    return '';
                }

                if (q.type === 'file') {
                    answerValue = '(file)';
                }
                if (q.type === 'checkbox') {
                    answerValue = answer.answer ? 'true' : 'false';
                }
                if (q.type === 'input' || q.type === 'textarea') {
                    answerValue = answer.answer;
                }
                if (q.type === 'select' || q.type === 'checkbox') {
                    answerValue = q.options.find(o => o.value === a.answer)?.label || '';
                }

                return {
                    questionText: answer.question.label,
                    answerText: answerValue,
                };
            });

            // strip out HTML tags and some entities for answer string
            this.formAnswerString = this.formattedAnswers.map((a) => a.answerText).toString()
                    .replace(/<[^>]+>/g, '')
                    .replace(/&(nbsp|amp|quot|lt|gt);/g, '')
                    .toLocaleLowerCase();
        }
    }
}
