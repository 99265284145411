import { map, tap } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Routes } from '../../config/routes';
import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { DepositBankAccount } from '../../models/entities/deposit-bank-account';
import { HistoricalBalance, UnifiedBankAccount } from '../../models/entities/unified-bank-account';
import { PaymentBankAccount } from '../../models/entities/payment-bank-account';
import { PaymentAttempt } from 'app/models/entities/payment-attempt';
import moment from 'moment-timezone';
import { Transaction } from '../../models/entities/transactions/transaction';
import { BankAccountPropertiesFormValue } from '../../components/app-layout/shared/bank-account-manager/bank-account-properties/bank-account-properties.component';
import { GtmService } from '../gtm.service';

export class AssetBalance
{
    id: number;
    name: string;
    organizationId: number;
    ownerType: string;
    ownerId: number;
    balance: number;
    internalBalance: number;
    lastUpdated: string;

    constructor(request: any) {
        this.id = request.id;
        this.name = request.name;
        this.organizationId = request.organizationId;
        this.ownerType = request.ownerType;
        this.ownerId = request.ownerId;
        this.balance = request.balance;
        this.internalBalance = request.internalBalance;
        this.lastUpdated = request.lastUpdated;
    }
}

export interface ReplaceDepositAccountRequest
{
    routing: string;
    account: string;
}

export interface PlaidFeedResponse
{
    accountId: string;
    amount: number;
    date: string;
    name: string;
    pending: boolean;
    transactionId: string;
    payhoaTransaction: Transaction;
    matchedTransaction: Transaction;
    beforeRelink: boolean;
    platformDeposit: boolean;
    lockboxDeposit: boolean;
    importable: boolean;
}

export interface BankAccountStreams
{
    $loaded: BehaviorSubject<boolean>;
    $loading: BehaviorSubject<boolean>;
    $isAlliance: BehaviorSubject<boolean>;
    $bankAccounts: BehaviorSubject<UnifiedBankAccount[]>;
}

@Injectable({
    providedIn: 'root',
})
export class BankAccountsService
{
    unifiedBankAccountChanged: EventEmitter<UnifiedBankAccount> = new EventEmitter<UnifiedBankAccount>();
    paymentBankAccountChanged: EventEmitter<PaymentBankAccount> = new EventEmitter<PaymentBankAccount>();

    private _bankAccounts: { [orgId: number]: UnifiedBankAccount[] } = {};
    private _orgStreams: {
        [orgId: number]: BankAccountStreams
    } = {};

    constructor(private _http: ApplicationHttpClient, private _gtm: GtmService) {
    }

    getOrganizationStreams(organizationId: number) {
        if (organizationId === null) {
            const jwt: JwtLegFiClaims = LegFiJwtService.read();
            organizationId = jwt.orgId;
        }

        if (this._orgStreams[organizationId]) {
            return this._orgStreams[organizationId];
        }

        const $bankAccounts = new BehaviorSubject<UnifiedBankAccount[]>([]);
        const streams = {
            $loaded: new BehaviorSubject<boolean>(false),
            $loading: new BehaviorSubject<boolean>(false),
            $isAlliance: new BehaviorSubject<boolean>(false),
            $bankAccounts: $bankAccounts,
        };
        this._orgStreams[organizationId] = streams;
        return streams;
    }

    createFixedAssetForAccount(unifiedBankAccountId: number) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.CreateOrgBankAccountAsset(jwt.orgId, unifiedBankAccountId)), JSON.stringify({}));
    }

    importTransactionsFromPlaid(accountId: number, options: {
        startDate: moment.Moment, endDate: moment.Moment
    }, transactionIds: string[]): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.post(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.LinkedAccountPlaidImport(jwt.orgId, accountId)), JSON.stringify({transactions: transactionIds, ...options}));
    }

    getPlaidFeed(accountId: number, options: {
        startDate: moment.Moment, endDate: moment.Moment
    }): Observable<PlaidFeedResponse[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.LinkedAccountPlaidFeed(jwt.orgId, accountId)) + '?' + this._http.makeQueryParameters(options).join('&'));
    }

    getDepositAccountPendingFunds(depositAccountId: number): Observable<PaymentAttempt[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.get(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgDepositAccountPendingPayments(jwt.orgId, depositAccountId))).pipe(map((response: Object[]): PaymentAttempt[] => {
            return response.map((attempt) => {
                return new PaymentAttempt(attempt);
            });
        }));
    }


    /**
     * Get the specified organization's bank accounts.
     * @param {number} organizationId
     * @param withs
     * @param createStream
     * @returns {Observable<UnifiedBankAccount[]>}
     */
    getOrgBankAccounts(organizationId: number,
                       withs: ('trashed' | 'plaidToken' | 'fixedAsset' | 'depositBankAccount' | 'reconciliations' | 'onlyTrashed')[] = null,
                       createStream: boolean = true,
    ): Observable<UnifiedBankAccount[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params = [];
        if (withs !== null) {
            if (withs.length === 0) {
                params.push('with[]=');
            } else {
                params.push(...withs.map(w => 'with[]=' + w));
            }
        }

        organizationId = organizationId || jwt.orgId;
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgBankAccounts(organizationId)) + (params.length > 0
                ? '?' + params.join('&')
                : '');
        if (createStream) {
            const streams = this.getOrganizationStreams(organizationId);
            streams.$loading.next(true);
            return this._http.get(url).pipe(map((response: Object[]): UnifiedBankAccount[] => {
                let isAlliance = false;
                const bankAccounts = response.map((bank) => {
                    const bankAccount = new UnifiedBankAccount(bank);
                    if (bankAccount.isAllianceAccount) {
                        isAlliance = true;
                    }
                    return bankAccount;
                });
                this._bankAccounts[organizationId] = bankAccounts;
                streams.$bankAccounts.next(this._bankAccounts[organizationId]);
                streams.$isAlliance.next(isAlliance);
                streams.$loading.next(false);
                streams.$loaded.next(true);
                return bankAccounts;
            }));
        } else {
            return this._http.get(url).pipe(map((response: Object[]): UnifiedBankAccount[] => {
                return response.map((bank) => new UnifiedBankAccount(bank));
            }));
        }
    }

    getOrgBankAccount(organizationId: number, accountId: number): Observable<UnifiedBankAccount> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgBankAccount(organizationId || jwt.orgId, accountId));

        return this._http.get(url).pipe(map((response: Object): UnifiedBankAccount => {
            return new UnifiedBankAccount(response);
        }));
    }

    /**
     * Get the specified organization-owned deposit bank accounts.
     * @param {number} organizationId
     * @param {{}} options
     * @returns {Observable<DepositBankAccount[]>}
     */
    getOrgDepositAccounts(organizationId: number): Observable<DepositBankAccount[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgDepositAccounts(organizationId || jwt.orgId));

        return this._http.get(url).pipe(map((response: Object[]): DepositBankAccount[] => {
            return response.map(depositBankAccount => new DepositBankAccount(depositBankAccount));
        }));
    }

    /**
     * @param {number} organizationId
     * @param {any} body
     * @returns {Observable<UnifiedBankAccount>}
     */
    createOrganizationBankAccount(organizationId: number,
                                  body: BankAccountPropertiesFormValue,
    ): Observable<UnifiedBankAccount> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        organizationId = organizationId || jwt.orgId;
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgBankAccounts(organizationId));

        const streams = this.getOrganizationStreams(organizationId);
        return this._http.post(url, JSON.stringify(body)).pipe(map((res) => {
            this._gtm.addBankAccount({
                orgId: organizationId, addedBy: jwt.id,
            });
            return res;
        }), map((res) => {
            streams.$loaded.next(false);
            return new UnifiedBankAccount(res);
        }));
    }


    /**
     * @param {number} bankAccountId
     * @param {any} body
     * @returns {Observable<UnifiedBankAccount>}
     */
    updateUnifiedAccount(bankAccountId: number, body: any): Observable<UnifiedBankAccount> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        return this._http.patch(Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgBankAccount(jwt.orgId, bankAccountId)), body).pipe(map((response: Object): UnifiedBankAccount => {
            return new UnifiedBankAccount(response);
        }));
    }


    refreshDepositStripeFields(depositBankAccountId: number) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.RefreshOrgDepositAccount(jwt.orgId, depositBankAccountId));

        return this._http.post(url, JSON.stringify({})).pipe(map((res: any) => res.token));
    }

    /**
     * @param organizationId
     * @param bankId
     * @param replacementAccount
     */
    removeDepositAccount(organizationId: number, bankId: number, replacementAccount: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgBankAccount(organizationId || jwt.orgId, bankId) + '/deposit?replacement=' + replacementAccount);

        return this._http.delete(url);
    }

    /**
     * Remove organization-owned bank account as a valid payment method.
     * @param {number} organizationId
     * @param {number} bankAccountId
     * @param {number} replacementAccountId
     * @returns {Observable}
     */
    removeBankAccountFromOrg(organizationId: number,
                             bankAccountId: number,
                             replacementAccountId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        organizationId = organizationId || jwt.orgId;
        const query = replacementAccountId ? '?newBank=' + replacementAccountId : '';
        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrgBankAccount(organizationId, bankAccountId) + query);

        const streams = this.getOrganizationStreams(organizationId);
        return this._http.delete(url).pipe(tap(() => streams.$loaded.next(false)));
    }


    /**
     * @param {PaymentBankAccount} bankAccount
     */
    paymentBankAccountChange(bankAccount: PaymentBankAccount) {
        this.paymentBankAccountChanged.emit(bankAccount);
    }

    getPlaidBalanceHistory(organizationId: number, unifiedBankAccountId: number): Observable<HistoricalBalance[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.PlaidBalanceHistory(organizationId || jwt.orgId, unifiedBankAccountId));

        return this._http.get(url);
    }
}
