import moment from 'moment-timezone';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { Note } from './note';
import { PaymentAttempt } from './payment-attempt';
import { Membership } from './membership';
import { Organization } from './organization';
import { Site } from './site';
import { User } from './user';
import { Owner } from './owner';
import { Unit } from './unit';
import Moment = moment.Moment;

export class PaymentGroup
{
    id: number;
    organizationId: number;
    payorId: number;
    payorType: string;
    paymentMethodType: string;
    paymentMethodValue: string;
    net: number;
    applied: number;
    gross: number;
    currency: string;
    autopaid: boolean;
    createdAt: Moment;
    updatedAt: Moment;
    importedAt: Moment;

    organization: Organization;
    payor: Membership | Organization | Site | Unit | User | Owner;
    notes: Note[];
    paymentAttempts: PaymentAttempt[];

    // Appends
    absorbedFees: number = 0;
    offloadedFees: number = 0;
    escrow: number = 0;
    processor: string;
    hasValidPayments: boolean;

    checked: boolean;
    hidden: boolean;

    deletable = false;

    constructor(paymentGroup: any) {
        if (paymentGroup.id) {
            this.id = paymentGroup.id;
        }
        if (paymentGroup.organizationId) {
            this.organizationId = paymentGroup.organizationId;
        }
        if (paymentGroup.payorId) {
            this.payorId = paymentGroup.payorId;
        }
        if (paymentGroup.payorType) {
            this.payorType = paymentGroup.payorType;
        }
        if (paymentGroup.paymentMethodType) {
            this.paymentMethodType = paymentGroup.paymentMethodType;
        }
        if (paymentGroup.paymentMethodValue) {
            this.paymentMethodValue = paymentGroup.paymentMethodValue;
        }
        if (paymentGroup.net !== null) {
            this.net = paymentGroup.net;
        }
        if (paymentGroup.applied !== null) {
            this.applied = paymentGroup.applied;
        }
        if (paymentGroup.gross !== null) {
            this.gross = paymentGroup.gross;
        }
        if (paymentGroup.currency) {
            this.currency = paymentGroup.currency.toLocaleUpperCase();
        }

        const timezone = LegFiJwtService.getTimezone();
        if (paymentGroup.createdAt) {
            this.createdAt = moment.utc(paymentGroup.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentGroup.updatedAt) {
            this.updatedAt = moment.utc(paymentGroup.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (paymentGroup.importedAt) {
            this.importedAt = moment.utc(paymentGroup.importedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (typeof paymentGroup.absorbedFees !== 'undefined' && paymentGroup.absorbedFees !== null) {
            this.absorbedFees = paymentGroup.absorbedFees;
        }
        if (typeof paymentGroup.offloadedFees !== 'undefined' && paymentGroup.offloadedFees !== null) {
            this.offloadedFees = paymentGroup.offloadedFees;
        }
        if (typeof paymentGroup.escrow !== 'undefined' && paymentGroup.escrow !== null) {
            this.escrow = paymentGroup.escrow;
        }
        if (paymentGroup.processor) {
            this.processor = paymentGroup.processor;
        }
        if (paymentGroup.hasValidPayments) {
            this.hasValidPayments = paymentGroup.hasValidPayments;
        }

        if (paymentGroup.organization) {
            this.organization = new Organization(paymentGroup.organization);
        }
        if (paymentGroup.payor && paymentGroup.payorType !== null) {
            if (paymentGroup.payorType.indexOf('Membership') !== -1) {
                this.payor = new Membership(paymentGroup.payor);
            } else if (paymentGroup.payorType.indexOf('Organization') !== -1) {
                this.payor = new Organization(paymentGroup.payor);
            } else if (paymentGroup.payorType.indexOf('Site') !== -1) {
                this.payor = new Site(paymentGroup.payor);
            } else if (paymentGroup.payorType.indexOf('User') !== -1) {
                this.payor = new User(paymentGroup.payor);
            } else if (paymentGroup.payorType.indexOf('Owner') !== -1) {
                this.payor = new Owner(paymentGroup.payor);
            }
        }
        if (paymentGroup.notes) {
            this.notes = paymentGroup.notes.map((note) => {
                return new Note(note);
            });
        }
        if (paymentGroup.paymentAttempts) {
            this.paymentAttempts = paymentGroup.paymentAttempts.map((paymentAttempt) => {
                this.autopaid = paymentAttempt.autopaid;
                return new PaymentAttempt(paymentAttempt);
            });
        }

        if (!(this.paymentMethodType.indexOf('BankAccount') !== -1
                || this.paymentMethodType.indexOf('CreditCard') !== -1
                || this.paymentMethodType === 'CardToken')) {
            this.deletable = true;
        }
    }

    get isLinked() {
        return this.paymentAttempts.map(pa => pa.transactionsCount).reduce((a, b) => a + b, 0) > 0;
    }

    get friendlyPaymentMethod() {
        if (this.paymentMethodType.indexOf('BankAccount') !== -1) {
            return 'Bank Account';
        }
        if (this.paymentMethodType.indexOf('CreditCard') !== -1) {
            return 'Credit Card';
        }
        if (this.paymentMethodType === 'CardToken') {
            return 'Credit Card';
        }
        if (this.paymentMethodType === 'OfflineCheck') {
            return 'Check (offline)';
        }
        if (this.paymentMethodType === 'OfflineCash') {
            return 'Cash (offline)';
        }
        if (this.paymentMethodType === 'AccountCredit') {
            return 'Account Credit';
        }
        if (this.paymentMethodType === 'Mailed') {
            return 'Mailed Payment';
        }
    }

    get payables() {
        return this.paymentAttempts.flatMap(pa => pa.paymentItems).map(pi => pi.payable);
    }
}
