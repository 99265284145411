import { DataSource } from '@angular/cdk/collections';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { PaymentGroup } from '../../models/entities/payment-group';
import { PaymentGroupsService } from './payment-groups.service';
import { Moment } from 'moment';

export interface PaymentsDatasourceOptions
{
    paymentMethodType?: string[];
    category?: number;
    depositAccount?: number;
    time?: number;
    start?: Moment;
    end?: Moment;
    csv?: number;
    hideDeleted?: boolean;
    hideMapped?: boolean;
    maxAmount?: number;
    preselectedPaymentIds?: number[];
}

export interface AdminPaymentsDatasourceOptions
{
    organizationId?: number;
    paymentMethodType?: string[];
    year?: number;
    startDate?: Moment;
    endDate?: Moment;
    csv?: number;
}

export const PaymentsPageSizeOptions = [
    25,
    50,
    100,
    200,
    400,
    600,
    800,
    1000,
];

export class PaymentsDatasource implements DataSource<PaymentGroup>
{
    private transactionSubject = new BehaviorSubject<PaymentGroup[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private countSubject = new BehaviorSubject<number>(0);

    private metaSubject = new Subject<PaymentsPaginationMeta>();

    public loading$ = this.loadingSubject.asObservable();
    public count$ = this.countSubject.asObservable();
    public meta$ = this.metaSubject.asObservable();

    private cancelCurrentRequest = new Subject();

    constructor(private paymentGroupsService: PaymentGroupsService) {
    }

    loadPayments(
            orgId: number,
            search: string = '',
            sortColumn: string = 'createdAt',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: PaymentsDatasourceOptions = {},
    ) {
        options.csv = 0;
        this.loadingSubject.next(true);

        this.cancelCurrentRequest.next(true);

        this.paymentGroupsService.getIncomingPaymentGroups(orgId, search, sortColumn, sortDirection, pageIndex, perPage, options)
                .pipe(takeUntil(this.cancelCurrentRequest))
                .pipe(
                        catchError((err) => of({data: [], meta: {total: 0}}).pipe(tap(_ => console.error(err)))),
                )
                .subscribe((charges: PaymentsResponse) => {
                    this.transactionSubject.next(charges.data);
                    const total = (charges.meta.total) ? charges.meta.total : 0;
                    this.metaSubject.next(charges.meta);
                    this.countSubject.next(total);
                    this.loadingSubject.next(false);
                });
    }

    getCsv(
            orgId: number,
            search: string = '',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            options: PaymentsDatasourceOptions = {},
            perPage: number = -1,
    ) {
        options.csv = 1;
        return this.paymentGroupsService.getIncomingPaymentGroups(orgId, search, sortColumn, sortDirection, 1, perPage, options);
    }

    connect(): Observable<PaymentGroup[]> {
        return this.transactionSubject.asObservable();
    }

    disconnect(): void {
        this.transactionSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }

}


export class UnitPaymentsDatasource implements DataSource<PaymentGroup>
{
    private transactionSubject = new BehaviorSubject<PaymentGroup[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private countSubject = new BehaviorSubject<number>(0);

    private metaSubject = new BehaviorSubject<PaymentsPaginationMeta>(null);

    public loading$ = this.loadingSubject.asObservable();
    public count$ = this.countSubject.asObservable();
    public meta$ = this.metaSubject.asObservable();

    private cancelCurrentRequest = new Subject();

    constructor(private paymentGroupsService: PaymentGroupsService) {
    }

    loadPayments(
            orgId: number,
            unitId: number,
            search: string = '',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: PaymentsDatasourceOptions = {},
    ) {

        this.loadingSubject.next(true);

        this.cancelCurrentRequest.next(true);

        this.paymentGroupsService.getUnitPaymentGroups(orgId, unitId, search, sortColumn, sortDirection, pageIndex, perPage, options)
                .pipe(takeUntil(this.cancelCurrentRequest))
                .pipe(
                        catchError((err) => of({data: [], meta: {total: 0}}).pipe(tap(_ => console.error(err)))),
                )
                .subscribe((charges: PaymentsResponse) => {
                    this.transactionSubject.next(charges.data);
                    const total = (charges.meta.total) ? charges.meta.total : 0;
                    this.metaSubject.next(charges.meta);
                    this.countSubject.next(total);
                    this.loadingSubject.next(false);
                });
    }

    getCsv(
            orgId: number,
            unitId: number,
            search: string = '',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            options: PaymentsDatasourceOptions = {},
    ) {
        options.csv = 1;
        return this.paymentGroupsService.getUnitPaymentGroups(orgId, unitId, search, sortColumn, sortDirection, 1, -1, options);
    }

    connect(): Observable<PaymentGroup[]> {
        return this.transactionSubject.asObservable();
    }

    disconnect(): void {
        this.transactionSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }

}


export class AdminPaymentsDatasource implements DataSource<PaymentGroup>
{
    private transactionSubject = new BehaviorSubject<PaymentGroup[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private countSubject = new BehaviorSubject<number>(0);

    private metaSubject = new BehaviorSubject<PaymentsPaginationMeta>(null);

    public loading$ = this.loadingSubject.asObservable();
    public count$ = this.countSubject.asObservable();
    public meta$ = this.metaSubject.asObservable();

    private cancelCurrentRequest = new Subject();

    constructor(private paymentGroupsService: PaymentGroupsService) {
    }

    loadPayments(
            search: string = '',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: AdminPaymentsDatasourceOptions = {},
    ) {

        this.loadingSubject.next(true);

        this.cancelCurrentRequest.next(true);

        this.paymentGroupsService.getAdminPaymentGroups(search, sortColumn, sortDirection, pageIndex, perPage, options)
                .pipe(takeUntil(this.cancelCurrentRequest))
                .pipe(
                        catchError((err) => of({data: [], meta: {total: 0}}).pipe(tap(_ => console.error(err)))),
                )
                .subscribe((charges: PaymentsResponse) => {
                    this.transactionSubject.next(charges.data);
                    const total = (charges.meta.total) ? charges.meta.total : 0;
                    this.metaSubject.next(charges.meta);
                    this.countSubject.next(total);
                    this.loadingSubject.next(false);
                });
    }

    getCsv(
            search: string = '',
            sortColumn: string = 'activeAfter',
            sortDirection: string = 'desc',
            options: AdminPaymentsDatasourceOptions = {},
    ) {
        options.csv = 1;
        return this.paymentGroupsService.getAdminPaymentGroups(search, sortColumn, sortDirection, 1, -1, options);
    }

    connect(): Observable<PaymentGroup[]> {
        return this.transactionSubject.asObservable();
    }

    disconnect(): void {
        this.transactionSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }

}


export class PaymentsResponse
{
    data: PaymentGroup[];
    meta: PaymentsPaginationMeta;

    constructor(request: any) {
        this.data = (request.data && request.data.length) ? request.data.map(tx => {
            return new PaymentGroup(tx);
        }) : [];
        this.meta = new PaymentsPaginationMeta(request.meta);
    }
}


export class PaymentsPaginationMeta
{
    currentPage: number;
    from: number;
    lastPage: number;
    path: string;
    perPage: number;
    to: number;
    total: number;
    original: number;
    totalFees: number;
    totalNet: number;
    totalAmount: number;

    constructor(meta: any) {
        this.currentPage = meta.current_page || 0;
        this.from = meta.from || 0;
        this.lastPage = meta.last_page || 0;
        this.path = meta.path || null;
        this.perPage = meta.per_page || 0;
        this.to = meta.to || 0;
        this.total = meta.total || 0;
        this.original = meta.original || 0;
        this.totalFees = meta.totalFees || 0;
        this.totalNet = meta.totalNet || 0;
        this.totalAmount = meta.totalAmount || 0;
    }
}
