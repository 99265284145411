import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GrowlerService } from '../../../../../services/growler.service';
import { FormSubmissionStatus } from '../../../../../models/entities/forms/form-submission-status';
import { RequestFormService } from '../../../../../services/forms/request-form.service';
import { AdjustColorValues } from '../../../../../directives/adjust-color/adjust-color.directive';
import { KeyValue } from '@angular/common';
import { Tag } from '../../../../../models/entities/tag';
import { TagsDataService } from '../../../../../services/tags/tags-data.service';
import { ActivatedRoute, Router } from '@angular/router';

export enum FormSubmissionStates {
    OPEN = 'open',
    CLOSED = 'closed',
    ALL = 'all',
}

export interface FormSubmissionStatusFilter {
    id: number;
    name: string;
    color: string;
    value: boolean;
}

export interface FormSubmissionFilters {
    state: FormSubmissionStates;
    statuses: FormSubmissionStatusFilter[];
    allStatusesVisible: () => boolean;
    unitTags: Tag[];
    submissionTags: Tag[];
}

@UntilDestroy()
@Component({
    selector: 'app-form-submission-table-filters',
    templateUrl: './form-submission-table-filters.component.html',
    styleUrls: ['./form-submission-table-filters.component.scss'],
})
export class FormSubmissionTableFiltersComponent implements OnChanges, OnInit
{
    @Input() formId: number;
    @Input() initialFilters: any = {};

    @Output() closeFilters: EventEmitter<void> = new EventEmitter<void>();
    @Output() loadFormSubmissions: EventEmitter<void> = new EventEmitter<void>();
    @Output() onOrgStatusesLoaded: EventEmitter<FormSubmissionStatus[]> = new EventEmitter<FormSubmissionStatus[]>();
    @Output() onFiltersUpdated: EventEmitter<FormSubmissionFilters> = new EventEmitter<FormSubmissionFilters>();

    form: FormGroup = this._fb.group({
        state: [FormSubmissionStates.OPEN],
        statuses: this._fb.array([]),
    });

    formSubmissionFilters: FormSubmissionFilters = null;

    readonly formSubmissionStates = FormSubmissionStates;
    readonly adjustColorValues = AdjustColorValues;

    constructor(
            private _router: Router,
            private _activatedRoute: ActivatedRoute,
            private _formService: RequestFormService,
            private _tagsDataService: TagsDataService,
            private _fb: FormBuilder,
            private _growler: GrowlerService,
    ) {
    }

    originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
        return 0;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('formId' in changes) {
            this.getFormSubmissionFilters();
        } else if ('initialFilters' in changes) {
            this.setInitialFilters();
        }
    }

    ngOnInit() {
        this._tagsDataService.getOrganizationTagsByType('Unit').subscribe();
        this.onFiltersUpdated.pipe(untilDestroyed(this)).subscribe(() => {
            return this._router.navigate(
                    [],
                    {
                        relativeTo: this._activatedRoute,
                        queryParams: { state: this.formSubmissionFilters.state },
                    },
            );
        });
    }

    getFormSubmissionFilters() {
        // just capturing state and statuses for now
        this._formService.getOrgFormSubmissionStatuses(this.formId).pipe(untilDestroyed(this)).subscribe({
            next: (statuses: FormSubmissionStatus[]) => {
                this.formSubmissionFilters = this.setFiltersFromResponse(statuses);
                this.onOrgStatusesLoaded.emit(statuses);

                this.setInitialFilters();
            },
            error: () => this._growler.oops('Unable to load form submission filters.'),
        });
    }

    resetForm() {
        this.form.get('state').setValue(FormSubmissionStates.OPEN);
        const statusControls = this.form.get('statuses') as FormArray;
        for (const control of (statusControls.controls as FormControl[])) {
            control.setValue(false);
        }
        this.formSubmissionFilters.unitTags = [];
    }

    clearFilters() {
        this.resetForm();
        this.onFiltersUpdated.emit(this.formSubmissionFilters);
        this.closeFilters.emit();
    }

    private setFiltersFromResponse(response: FormSubmissionStatus[]): FormSubmissionFilters {
        const statusControls = this.form.get('statuses') as FormArray;
        statusControls.clear();

        const statuses: FormSubmissionStatusFilter[] = [];
        if (response.length > 0) {
            // add 'No Status' to handle submissions without a custom status set
            // ...but only when other custom statuses have been created
            response = [
                new FormSubmissionStatus({
                    id: -1,
                    title: 'No Status',
                    color: '#ced4da',
                }),
                ...response
            ];
        }

        for (const status of response) {
            const control = this._fb.control(false);
            control.valueChanges.pipe(untilDestroyed(this)).subscribe({
                next: (value) => this.formSubmissionFilters.statuses.find((s) => s.id === status.id).value = value,
            });

            statuses.push({
                id: status.id,
                name: status.title,
                color: status.color,
                value: control.value,
            });
            statusControls.push(control);
        }

        this.form.get('state').valueChanges.pipe(untilDestroyed(this)).subscribe({
            next: (state) => this.formSubmissionFilters.state = state,
        });

        return {
            state: this.form.get('state').value,
            statuses,
            allStatusesVisible: () => {
                const count = this.formSubmissionFilters.statuses.filter((status) => status.value).length;
                return count === 0 || count === this.formSubmissionFilters.statuses.length;
            },
            unitTags: [],
            // managed in above the mat-table separately
            submissionTags: this.formSubmissionFilters?.submissionTags || [],
        };
    }

    private setInitialFilters() {
        for (const key in this.initialFilters) {
            if (this.initialFilters.hasOwnProperty(key)) {
                this.form.get(key)?.setValue(this.initialFilters[key]);
            }
        }
        this.onFiltersUpdated.emit(this.formSubmissionFilters);
    }
}
