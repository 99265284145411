import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Routes } from '../../config/routes';

import { LegFiJwtService } from '../auth/legfi-jwt.service';
import { Charge } from '../../models/entities/charge';
import { JwtLegFiClaims } from '../auth/jwt-legfi-claims.model';
import { ApplicationHttpClient } from '../../components/shared/http/application-http-client';
import { RecordOfflinePayment } from '../../components/app-layout/payments/record-owner-payment/record-offline-payment.model';
import { PaymentGroup } from '../../models/entities/payment-group';
import { AccountCredit } from 'app/models/entities/account-credit';
import { PaymentsDatasourceOptions, PaymentsResponse } from './payments.datasource';
import { Moment } from 'moment';
import { Transaction } from '../../models/entities/transactions/transaction';

@Injectable({
    providedIn: 'root',
})
export class PaymentGroupsService
{
    private _http: ApplicationHttpClient;

    constructor(_http: ApplicationHttpClient) {
        this._http = _http;
    }

    /**
     * Gets specific payments received by organization from Core.
     * @param {number} organizationId
     * @param {number} paymentId
     * @returns {Observable<PaymentGroup>}
     */
    public getIncomingPaymentGroup(
            organizationId: number,
            paymentId: number,
    ): Observable<PaymentGroup> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IncomingPaymentGroup(
                        organizationId || jwt.orgId,
                        paymentId,
                ),
        );

        return this._http.get(url).pipe(map((response: Object) => {
            return new PaymentGroup(response);
        }));
    }

    /**
     * @param organizationId
     * @param paymentId
     */
    public issueCreditForPaymentDifference(
            organizationId: number,
            paymentId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IncomingPaymentGroup(
                        organizationId || jwt.orgId,
                        paymentId,
                ) + '/issue-credit/missing',
        );

        return this._http.post(url, JSON.stringify({}));
    }

    /**
     * Gets filters used for incoming payments
     * @param organizationId
     */
    public getPaymentFilters(organizationId: number) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IncomingPaymentsFilters(organizationId || jwt.orgId),
        );

        return this._http.get(url);
    }

    /**
     * Gets filters used for incoming payments
     */
    public getAdminPaymentFilters() {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.AdminPaymentFilters(),
        );

        return this._http.get(url);
    }

    /**
     * Gets specific payments received by organization from Core.
     * @param orgId
     * @param search
     * @param status
     * @param sortColumn
     * @param sortDirection
     * @param pageIndex
     * @param perPage
     * @param {{limit?:number, year?:number}} options
     * @returns {Observable<PaymentsResponse>}
     */
    public getIncomingPaymentGroups(
            orgId: number,
            search: string = '',
            sortColumn: string = 'createdAt',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: PaymentsDatasourceOptions = {},
    ): Observable<PaymentsResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + search);
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('perPage=' + perPage);
        if (typeof options !== 'undefined') {
            params.push('filters=' + JSON.stringify(options));
        }

        let url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.IncomingPaymentGroups(orgId || jwt.orgId));
        url = url + '?' + params.join('&');

        if (options.csv) {
            return this._http.get(url, {responseType: 'blob'});
        } else {
            return this._http.get(url).pipe(map((resp) => {
                return new PaymentsResponse(resp);
            }));
        }
    }

    /**
     *
     * @param orgId
     * @param search
     * @param sortColumn
     * @param sortDirection
     * @param pageIndex
     * @param perPage
     * @param options
     */
    public getAdminPaymentGroups(
            search: string = '',
            sortColumn: string = 'createdAt',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: PaymentsDatasourceOptions = {},
    ): Observable<PaymentsResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + search);
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('perPage=' + perPage);
        if (typeof options !== 'undefined') {
            params.push('filters=' + JSON.stringify(options));
        }

        let url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.AdminPaymentGroups());
        url = url + '?' + params.join('&');

        if (options.csv) {
            return this._http.get(url, {responseType: 'blob'});
        } else {
            return this._http.get(url).pipe(map((resp) => {
                return new PaymentsResponse(resp);
            }));
        }
    }

    /**
     * Get payment history of payorId
     * @param {number} payorId
     * @param parentId
     * @param year
     * @returns {Observable<Object>}
     */
    public getPaymentHistory(
            payorId?: number,
            parentId?: number,
            year?: number,
    ): Observable<PaymentGroup[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IncomingPaymentGroups(payorId || jwt.orgId) +
                '/history',
        );

        if (parentId) {
            url = url + '?parent=' + parentId;
            if (year) {
                url = url + '&year=' + year;
            }
        } else {
            if (year) {
                url = url + '?year=' + year;
            }
        }

        return this._http.get(url).pipe(map((res: Object[]) => {
            return res.map(pg => new PaymentGroup(pg));
        }));
    }

    /**
     * Gets payments made by payor from Core.
     * @param {number} organizationId
     * @param memberId
     * @param {number[]} paymentGroupIds optional
     * @returns {Observable<PaymentGroup[]>}
     */
    public getMemberPaymentGroups(
            organizationId: number,
            memberId: number,
            paymentGroupIds?: number[],
    ): Observable<PaymentGroup[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params =
                paymentGroupIds && paymentGroupIds.length
                        ? '?paymentGroupIds=' + paymentGroupIds.join(',')
                        : '';
        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.MemberPaymentGroups(
                        organizationId,
                        memberId,
                ) + params,
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map(paymentGroup => {
                return new PaymentGroup(paymentGroup);
            });
        }));
    }

    /**
     * Gets account credits on this unit from all sources
     * @param {number} organizationId
     * @param {number} unitId
     * @param {number[]} paymentGroupIds optional
     * @returns {Observable<AccountCredit[]>}
     */
    public getUnitAccountCredits(
            organizationId: number,
            unitId: number,
    ): Observable<AccountCredit[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrganizationUnit(
                        organizationId || jwt.orgId,
                        unitId,
                ) +
                '/account-credits',
        );

        return this._http.get(url).pipe(map((response: Object[]) => {
            return response.map(paymentGroup => {
                return new AccountCredit(paymentGroup);
            });
        }));
    }

    /**
     * Delete account credit
     */
    public deleteUnitAccountCredit(
            organizationId: number,
            unitId: number,
            creditId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrganizationUnit(
                        organizationId || jwt.orgId,
                        unitId,
                ) +
                '/account-credits/' + creditId,
        );

        return this._http.delete(url);
    }

    /**
     * Restore account credit
     */
    public restoreUnitAccountCredit(
            organizationId: number,
            unitId: number,
            creditId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrganizationUnit(
                        organizationId || jwt.orgId,
                        unitId,
                ) +
                '/account-credits/' + creditId + '/restore',
        );

        return this._http.post(url, JSON.stringify({}));
    }

    public processAccountCreditAsItCameIn(
            organizationId: number,
            unitId: number,
            creditId: number,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrganizationUnit(
                        organizationId || jwt.orgId,
                        unitId,
                ) +
                '/account-credits/' + creditId + '/process',
        );

        return this._http.post(url, JSON.stringify({}));
    }

    public processUnitCreditPayment(
            organizationId: number,
            unitId: number,
            chargeId: number = null,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        let url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OrganizationUnit(
                        organizationId || jwt.orgId,
                        unitId,
                ) + '/account-credits/process',
        );

        if (chargeId) {
            url += '?charge=' + chargeId;
        }

        return this._http.post(url, JSON.stringify({}));
    }

    public updatePaymentGroupTotals(organizationId: number, paymentGroupId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UpdatePaymentGroupTotals(
                        organizationId || jwt.orgId,
                        paymentGroupId,
                ),
        );

        return this._http.post(url, JSON.stringify({}));
    }

    /**
     * Gets payments made by payor from Core.
     * @param {number} organizationId
     * @param {number} unitId
     * @param search
     * @param status
     * @param sortColumn
     * @param sortDirection
     * @param pageIndex
     * @param perPage
     * @param options
     * @returns {Observable<PaymentGroup[]>}
     */
    public getUnitPaymentGroups(
            organizationId: number,
            unitId: number,
            search: string = '',
            sortColumn: string = 'createdAt',
            sortDirection: string = 'desc',
            pageIndex: number = 1,
            perPage: number = 100,
            options: PaymentsDatasourceOptions = {},
    ): Observable<PaymentsResponse> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const params = [];
        params.push('page=' + pageIndex);
        params.push('search=' + search);
        params.push('column=' + sortColumn);
        params.push('direction=' + sortDirection);
        params.push('perPage=' + perPage);
        if (typeof options !== 'undefined') {
            params.push('filters=' + JSON.stringify(options));
        }

        let url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.OrganizationUnit(organizationId || jwt.orgId, unitId));
        url = url + '/payment-groups?' + params.join('&');

        return this._http.get(url).pipe(map((response: Object[]) => {
            return new PaymentsResponse(response);
        }));
    }

    public recordLockboxPaymentGroups(
            organizationId: number,
            paymentRecords: {
                amount: number,
                payorId: number,
                note: string,
                paymentDate: string
            }[],
            sendReceipts: boolean, addToLedger: boolean, depositAccount: null,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.LockboxPaymentGroups(organizationId || jwt.orgId),
        );

        const request = {payments: paymentRecords, sendReceipts: sendReceipts};
        if (addToLedger) {
            request['addToLedger'] = true;
            request['depositAccount'] = depositAccount;
        }
        return this._http.post(url, JSON.stringify(request));
    }

    /**
     * Record payment records
     * @param {number} organizationId
     * @param {RecordOfflinePayment[]} paymentRecords optional
     * @param addToLedger
     * @param selectedAccountId
     * @param depositDate
     * @returns {Observable<PaymentGroup[]>}
     */
    public recordOfflinePaymentGroups(
            organizationId: number,
            paymentRecords: RecordOfflinePayment[],
            addToLedger: boolean = false,
            selectedAccountId: number = null,
            depositDate: Moment = null,
    ): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.OfflinePaymentGroups(organizationId || jwt.orgId),
        );

        const payments: {
            amount: number;
            payorId: number;
            payorType: string;
            note: string;
            paymentMethodType: string;
            charges: { chargeId: number; paymentAmount: number }[];
            sendReceipt: boolean;
            paymentDate: string;
        }[] = [];

        for (const record of paymentRecords) {
            payments.push({
                amount: record.amount,
                payorId: record.payor.id,
                payorType: 'owner',
                note: record.note,
                paymentMethodType: record.paymentMethodType,
                sendReceipt: record.sendReceipt,
                paymentDate: record.paymentDate.format('YYYY-MM-DD'),
                charges: record.charges
                        .filter((charge: Charge) => {
                            return charge.paymentAmount > 0;
                        })
                        .map((charge: Charge) => {
                            return {
                                chargeId: charge.id,
                                paymentAmount: charge.paymentAmount,
                            };
                        }),
            });
        }

        const requestData = {payments: payments};
        if (addToLedger) {
            requestData['addToLedger'] = true;
            requestData['accountId'] = selectedAccountId;
            requestData['depositDate'] = depositDate;
        }

        return this._http.post(url, JSON.stringify(requestData));
    }

    public linkOfflinePaymentGroups(paymentsToLink: number[], depositToLinkId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.LinkPaymentGroups(jwt.orgId),
        );

        const requestData = {
            paymentsToLink: paymentsToLink,
            depositToLinkId: depositToLinkId,
        };

        return this._http.post(url, JSON.stringify(requestData));
    }

    public unlinkOfflinePaymentGroups(paymentsToUnlink: number[], depositToUnlinkId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.UnlinkPaymentGroups(jwt.orgId),
        );

        const requestData = {
            paymentsToUnlink: paymentsToUnlink,
            depositToUnlinkId: depositToUnlinkId,
        };

        return this._http.post(url, JSON.stringify(requestData));
    }

    /**
     * Issue a credit for a given payment item
     * Must be called by a super
     */
    public issueCreditForPaymentItem(paymentItemId: number): Observable<Object> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url = Routes.MakeLegFiCoreUrl(
                Routes.LegFiCore.IssueCreditForPaymentItem(paymentItemId),
        );

        return this._http.post(url, JSON.stringify({}));
    }

    /**
     * Delete offline payment methods.
     * @param {number} organizationId
     * @param {number[]} paymentIds optional
     * @param {string} reason
     * @returns {Observable<PaymentGroup[]>}
     */
    public deleteOfflinePaymentGroups(
            organizationId: number,
            paymentIds: number[],
            reason?: string,
    ) {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        if (paymentIds.length === 0) {
            return;
        }

        const url: string =
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.OfflinePaymentGroups(
                                organizationId || jwt.orgId,
                        ),
                ) +
                '?' +
                paymentIds
                        .map(function (paymentId) {
                            return 'payments[]=' + paymentId;
                        })
                        .join('&') +
                (reason ? '&reason=' + encodeURIComponent(reason) : '');

        return this._http.delete(url);
    }

    /**
     * @param {number} organizationId
     * @param {number[]} paymentIds
     * @param {string} reason
     * @returns {Observable<PaymentGroup[]>}
     */
    public deletePaymentGroups(
            organizationId: number,
            paymentIds: number[],
            reason?: string,
    ): Observable<PaymentGroup[]> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string =
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.IncomingPaymentGroups(
                                organizationId || jwt.orgId,
                        ),
                ) +
                '?payments[]=' +
                paymentIds
                        .map(function (paymentId) {
                            return paymentId;
                        })
                        .join('&') +
                (reason ? '&reason=' + encodeURIComponent(reason) : '');

        return this._http.delete(url).pipe(map((response: Object[]) => {
            return response.map(paymentGroup => {
                return new PaymentGroup(paymentGroup);
            });
        }));
    }

    public refundPayment(orgId: number, paymentId: number): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }

        const url: string =
                Routes.MakeLegFiCoreUrl(
                        Routes.LegFiCore.IncomingPaymentGroup(
                                orgId || jwt.orgId,
                                paymentId,
                        ),
                );

        return this._http.delete(url);
    }

    public sendReceiptsForPaymentGroup(paymentGroupIds: number[]): Observable<any> {
        const jwt: JwtLegFiClaims = LegFiJwtService.read();
        if (jwt === null) {
            return this._http.redirectAndThrow401Observable();
        }
        const url: string = Routes.MakeLegFiCoreUrl(Routes.LegFiCore.IncomingPaymentsReceipts(jwt.orgId));

        return this._http.post(url, JSON.stringify({
            paymentGroupIds: paymentGroupIds,
        }));
    }
}
