import { User } from './user';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';
import { BillPayVendor } from './bill-pay-vendor';
import { UploadedFile } from './uploaded-file';
import Moment = moment.Moment;
import { InboundEmail } from './inbound-email';
import { UnifiedBankAccount } from './unified-bank-account';
import { Transaction } from './transactions/transaction';
import { PayableFulfillment } from './payable-fulfillment';

export interface ExpenseCategoryItem
{
    id: number;
    name?: string;
    label?: string;
    accountType: string;
}

export interface ExpenseCategory
{
    expenseCategory: ExpenseCategoryItem;
    expenseCategoryAmount: number;
}

export class Payable
{
    id: number;
    amount: number;
    confirmedBy: number;
    confirmedByUser: User;
    confirmedAt: Moment;
    dueDate: Moment;
    invoiceDate: Moment;
    invoiceNumber: string;
    invoiceUploadedFileId: number;
    memo: string;
    ocrProcessedAt: Moment;
    organizationId: number;
    payableBillNumber: number;
    payableStatus: string;
    payableStatusAt: Moment;
    paymentAmount: number;
    paymentType: string;
    purchaseOrder: string;
    scheduledPaymentDate: Moment;
    expectedDeliveryDate: Moment;
    paymentSentAt: Moment;
    terms: string;
    vendorId: number;
    payeeEin: string;
    payeeEmail: string;
    payeePhone: string;
    payeeName: string;
    payeeAddress1: string;
    payeeAddress2: string;
    payeeCity: string;
    payeeState: string;
    payeeZip: string;
    payeeCountry: string;
    createdAt: Moment;
    updatedAt: Moment;
    vendor: BillPayVendor;
    source: string;
    invoiceDescription: string;
    statusDescription: string;
    isCancellable: boolean;
    isDeletable: boolean;
    isSatisfied: boolean;
    isOverpayment: boolean;
    isPartialPayment: boolean;
    outstandingInvoiceAmount: number;
    payableUnifiedBankAccountId: number;
    account: UnifiedBankAccount;
    transactions: Transaction[];
    expenseCategories: ExpenseCategory[];
    fulfillments: PayableFulfillment[];
    currentFulfillment: PayableFulfillment;

    attachments: UploadedFile[] = [];
    inboundEmail: InboundEmail;

    constructor(payable: any) {
        if (payable.id) {
            this.id = payable.id;
        }
        this.amount = payable.amount || 0;
        if (payable.confirmedBy) {
            this.confirmedBy = payable.confirmedBy;
        }
        if (payable.invoiceNumber) {
            this.invoiceNumber = payable.invoiceNumber;
        }
        if (payable.invoiceUploadedFileId) {
            this.invoiceUploadedFileId = payable.invoiceUploadedFileId;
        }
        if (payable.memo) {
            this.memo = payable.memo;
        }
        if (payable.organizationId) {
            this.organizationId = payable.organizationId;
        }
        if (payable.payableBillNumber) {
            this.payableBillNumber = payable.payableBillNumber;
        }
        if (payable.payableStatus) {
            this.payableStatus = payable.payableStatus;
        }
        if (payable.paymentAmount) {
            this.paymentAmount = payable.paymentAmount;
        } else if (payable.amount) {
            // Temporarily set payment amount = amount until Payable can support partial/over payments
            this.paymentAmount = payable.amount;
        }
        if (payable.paymentType) {
            this.paymentType = payable.paymentType;
        }
        if (payable.purchaseOrder) {
            this.purchaseOrder = payable.purchaseOrder;
        }
        if (payable.terms) {
            this.terms = payable.terms;
        }
        if (payable.vendorId) {
            this.vendorId = payable.vendorId;
        }
        if (payable.payeeEin) {
            this.payeeEin = payable.payeeEin;
        }
        if (payable.payeeEmail) {
            this.payeeEmail = payable.payeeEmail;
        }
        if (payable.payeePhone) {
            this.payeePhone = payable.payeePhone;
        }
        if (payable.payeeName) {
            this.payeeName = payable.payeeName;
        }
        if (payable.payeeAddress1) {
            this.payeeAddress1 = payable.payeeAddress1;
        }
        if (payable.payeeAddress2) {
            this.payeeAddress2 = payable.payeeAddress2;
        }
        if (payable.payeeCity) {
            this.payeeCity = payable.payeeCity;
        }
        if (payable.payeeState) {
            this.payeeState = payable.payeeState;
        }
        if (payable.payeeZip) {
            this.payeeZip = payable.payeeZip;
        }
        if (payable.payeeCountry) {
            this.payeeCountry = payable.payeeCountry;
        }
        if (payable.invoiceDescription) {
            this.invoiceDescription = payable.invoiceDescription;
        }
        if (payable.statusDescription) {
            this.statusDescription = payable.statusDescription;
        }
        if (payable.isCancellable) {
            this.isCancellable = payable.isCancellable;
        }
        if (payable.isDeletable) {
            this.isDeletable = payable.isDeletable;
        }
        if (payable.isSatisfied) {
            this.isSatisfied = payable.isSatisfied;
        }
        if (payable.isOverpayment) {
            this.isOverpayment = payable.isOverpayment;
        }
        if (payable.isPartialPayment) {
            this.isPartialPayment = payable.isPartialPayment;
        }
        if (payable.outstandingInvoiceAmount) {
            this.outstandingInvoiceAmount = payable.outstandingInvoiceAmount || 0;
        }
        if (payable.expenseCategories) {
            this.expenseCategories = payable.expenseCategories;
        }
        if (payable.invoiceUploadedFileId) {
            this.invoiceUploadedFileId = payable.invoiceUploadedFileId;
        }
        if (payable.payableUnifiedBankAccountId) {
            this.payableUnifiedBankAccountId = payable.payableUnifiedBankAccountId;
        }

        // timestamps
        const timezone = LegFiJwtService.getTimezone();
        if (payable.createdAt) {
            this.createdAt = moment.utc(payable.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payable.confirmedAt) {
            this.confirmedAt = moment.utc(payable.confirmedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payable.updatedAt) {
            this.updatedAt = moment.utc(payable.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payable.ocrProcessedAt) {
            this.ocrProcessedAt = moment.utc(payable.ocrProcessedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payable.dueDate) {
            this.dueDate = moment(payable.dueDate);
        }
        if (payable.invoiceDate) {
            this.invoiceDate = moment(payable.invoiceDate);
        }
        if (payable.payableStatusAt) {
            this.payableStatusAt = moment(payable.payableStatusAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payable.scheduledPaymentDate) {
            this.scheduledPaymentDate = moment(payable.scheduledPaymentDate);
        }
        if (payable.expectedDeliveryDate) {
            this.expectedDeliveryDate = moment(payable.expectedDeliveryDate);
        }
        if (payable.paymentSentAt) {
            this.paymentSentAt = moment(payable.paymentSentAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (payable.source) {
            this.source = payable.source;
        }

        if (payable.attachments) {
            this.attachments = payable.attachments.map((attachment) => new UploadedFile(attachment));
        } else {
            this.attachments = [];
        }

        // relationships
        if (payable.confirmedByUser) {
            this.confirmedByUser = new User(payable.confirmedByUser);
        }
        if (payable.vendor) {
            this.vendor = new BillPayVendor(payable.vendor);
        }
        if (payable.inboundEmail) {
            this.inboundEmail = new InboundEmail(payable.inboundEmail);
        }
        if (payable.account) {
            this.account = new UnifiedBankAccount(payable.account);
        }
        if (payable.transactions) {
            this.transactions = payable.transactions.map((transaction) => new Transaction(transaction));
        }
        if (payable.fulfillments) {
            this.fulfillments = payable.fulfillments.map((fulfillment) => new PayableFulfillment(fulfillment));
        }
        if (payable.currentFulfillment) {
            this.currentFulfillment = new PayableFulfillment(payable.currentFulfillment);
        }
    }
}
