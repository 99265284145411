import { TransactionRuleAccount } from './transaction-rule-account';
import { TransactionRuleAction } from './transaction-rule-action';
import { TransactionRuleCondition } from './transaction-rule-condition';

export class TransactionRule
{
    id: number;
    organizationId: number;
    vendorId: number;
    name: string;
    type: string;
    autoApprove: boolean;
    matchAll: boolean;
    priority: number;

    accounts: TransactionRuleAccount[];
    conditions: TransactionRuleCondition[];
    actions: TransactionRuleAction[];

    constructor(rule: any) {
        this.id = rule.id || null;
        this.organizationId = rule.organizationId || null;
        this.vendorId = rule.vendorId || null;
        this.name = rule.name || null;
        this.type = rule.type || null;
        this.autoApprove = rule.autoApprove || false;
        this.matchAll = rule.matchAll || false;
        this.priority = rule.priority || 0;

        this.accounts = (rule.accounts && rule.accounts.length) ? rule.accounts.map(a => {
            return new TransactionRuleAccount(a);
        }) : [];
        this.conditions = (rule.conditions && rule.conditions.length) ? rule.conditions.map(a => {
            return new TransactionRuleCondition(a);
        }) : [];
        this.actions = (rule.actions && rule.actions.length) ? rule.actions.map(a => {
            return new TransactionRuleAction(a);
        }) : [];
    }
}

