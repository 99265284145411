import { User } from './user';
import moment, { Moment } from 'moment-timezone';

export class Audit
{
    id: number;
    userType: string;
    userId: number;
    event: string;
    auditableId: number;
    auditableType: string;
    oldValues: any;
    newValues: any;
    url: string;
    ipAddress: string;
    userAgent: string;
    auditBatchId: number;
    createdAt: Moment;
    updatedAt: Moment;

    user: User;

    constructor(obj: any) {
        this.id = obj.id || null;
        this.userType = obj.userType || null;
        this.userId = obj.userId || null;
        this.event = obj.event || null;
        this.auditableId = obj.auditableId || null;
        this.auditableType = obj.auditableType || null;
        this.oldValues = obj.oldValues || null;
        this.newValues = obj.newValues || null;
        this.url = obj.url || null;
        this.ipAddress = obj.ipAddress || null;
        this.userAgent = obj.userAgent || null;
        this.auditBatchId = obj.auditBatchId || null;

        if (obj.createdAt) {
            this.createdAt = moment.utc(obj.createdAt, 'YYYY-MM-DD hh:mm:ss').tz('America/New_York');
        }
        if (obj.createdAt) {
            this.createdAt = moment.utc(obj.createdAt, 'YYYY-MM-DD hh:mm:ss').tz('America/New_York');
        }

        if (obj.user) {
            this.user = new User(obj.user);
        }
    }
}
