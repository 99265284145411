import moment, { Moment } from 'moment-timezone';
import { FundingAttempt } from './funding-attempt';
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import { PaymentAttempt } from './payment-attempt';
import { Transaction } from './transactions/transaction';

export class FundingBatch
{
    id: number;
    createdAt: Moment;
    updatedAt: Moment;

    batchCount: number;
    batchTotal: number;

    transaction: Transaction;
    fundingAttempts: FundingAttempt[];
    fundingPaymentAttempts: PaymentAttempt[];
    paymentAttempts: PaymentAttempt[];

    constructor(fundingBatch: any) {
        if (fundingBatch.id) {
            this.id = fundingBatch.id;
        }

        const timezone = LegFiJwtService.getTimezone();
        if (fundingBatch.createdAt) {
            this.createdAt = moment.utc(fundingBatch.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }
        if (fundingBatch.updatedAt) {
            this.updatedAt = moment.utc(fundingBatch.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone);
        }

        if (fundingBatch.batchCount) {
            this.batchCount = fundingBatch.batchCount;
        }
        if (fundingBatch.batchTotal) {
            this.batchTotal = fundingBatch.batchTotal;
        }

        if (fundingBatch.transaction) {
            this.transaction = new Transaction(fundingBatch.transaction);
        }
        if (fundingBatch.fundingAttempts) {
            this.fundingAttempts = fundingBatch.fundingAttempts.map(
                    (fundingAttempt) => {
                        return new FundingAttempt(fundingAttempt);
                    },
            );
        }
        if (fundingBatch.fundingPaymentAttempts) {
            this.fundingPaymentAttempts = fundingBatch.fundingPaymentAttempts.map(
                    (fundingPaymentAttempt) => {
                        return new PaymentAttempt(fundingPaymentAttempt);
                    },
            );
        }
        if (fundingBatch.paymentAttempts) {
            this.paymentAttempts = fundingBatch.paymentAttempts.map(
                    (paymentAttempt) => {
                        return new PaymentAttempt(paymentAttempt);
                    },
            );
        }
    }
}
