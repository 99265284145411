import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService, AuditHistory } from '../../../../services/admin/admin.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GrowlerService } from '../../../../services/growler.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Audit } from '../../../../models/entities/audit';

export interface AuditHistoryDialogInput
{
    model: string;
    id: number;
}

@UntilDestroy()
@Component({
    selector: 'app-audit-history',
    templateUrl: './audit-history.component.html',
    styleUrls: ['./audit-history.component.scss'],
})
export class AuditHistoryComponent implements OnInit, AfterViewInit
{

    public isHistoryLoaded: boolean = false;
    public historyRecords: Audit[] = [];
    public object: any = null;
    public objectKeys: string[] = [];
    public relatedRecords: {
        [key: string]: {
            className: string;
            id: number;
        }[];
    }[] = [];
    public models: string[] = [
        'Address',
        'Charge',
        'Membership',
        'MembershipProfile',
        'Organization',
        'OrganizationProfile',
        'Owner',
        'PaymentGroup',
        'PaymentAttempt',
        'PaymentItem',
        'RecurringChargeTemplate',
        'Transaction',
        'Unit',
        'User',
    ];
    public relations: string[] = [];

    @ViewChild('idInput') public idInput: ElementRef;

    constructor(
            private _growler: GrowlerService,
            private _adminService: AdminService,
            public dialogRef: MatDialogRef<AuditHistoryComponent>,
            @Inject(MAT_DIALOG_DATA) public data: AuditHistoryDialogInput,
    ) {
    }

    ngOnInit() {
        if (this.data.model && this.data.id) {
            this.loadHistory();
        }
    }

    ngAfterViewInit() {
        fromEvent(this.idInput.nativeElement, 'keyup')
                .pipe(
                        debounceTime(500),
                        distinctUntilChanged(),
                        tap(() => {
                            this.loadHistory();
                        }),
                )
                .subscribe();
    }

    loadHistory() {
        this.isHistoryLoaded = false;
        if (this.data.model && this.data.id) {
            this._adminService.getAuditHistory(this.data.model, this.data.id)
                    .pipe(untilDestroyed(this))
                    .subscribe(
                            (records: AuditHistory) => {
                                this.object = records.record;
                                this.objectKeys = Object.keys(this.object);
                                this.historyRecords = records.audits;
                                this.relatedRecords = records.relatedRecords;
                                this.relations = Object.keys(this.relatedRecords);
                                this.isHistoryLoaded = true;
                            },
                    );
        }
    }

    selectRelatedRecord(key: string, value: number) {
        this.data.model = key;
        this.data.id = value;
        this.loadHistory();
    }
}
