<h3 mat-dialog-title>Audit History</h3>

<mat-dialog-content class="has-table">
    <div *ngIf="!isHistoryLoaded" class="d-loading card-body">
        <div class="loader">Loading...</div>
    </div>

    <ng-container *ngIf="isHistoryLoaded">
        <div *ngIf="object" class="py-2 px-4 scroll">
            <h3 class="font-weight-bold">Current Values:</h3>
            <div class="grid-layout grid-5 mt-0">
                <div class="d-flex flex-column" *ngFor="let index of objectKeys">
                    <label>{{index}}</label>
                    {{object[index]}}
                </div>
            </div>
        </div>


        <p *ngIf="!historyRecords.length" class="my-5 text-center">
            No history records to display.
        </p>

        <table *ngIf="isHistoryLoaded && historyRecords.length" class="table">
            <thead>
            <tr>
                <th>Date</th>
                <th>Event</th>
                <th>Before</th>
                <th>After</th>
                <th>By</th>
            </tr>
            </thead>
            <tbody class="scroll">
            <tr *ngFor="let record of historyRecords">
                <td>{{record.createdAt | legfiDate:'MM/DD/YYYY h:mm a'}}</td>
                <td>{{record.event}}</td>
                <td>
                    <pre>{{record.oldValues | legfiJson}}</pre>
                </td>
                <td>
                    <pre>{{record.newValues | legfiJson}}</pre>
                </td>
                <td>
                <span *ngIf="record.user">
                    <span *ngIf="record.url === 'console'">
                        {{record.user.email}} via Console
                    </span>
                    <span *ngIf="record.url !== 'console'">
                        {{record.user.email}} at {{record.ipAddress}}
                    </span>
                </span>
                    <span *ngIf="!record.user">
                    Console
                </span>
                    <ng-container *ngIf="record.auditBatchId">
                        <br>
                        <a (click)="selectRelatedRecord('AuditGroup', record.auditBatchId)" class="add-blue">Batch {{record.auditBatchId}}</a>
                    </ng-container>
                </td>
            </tr>
            </tbody>
        </table>
    </ng-container>
</mat-dialog-content>

<mat-dialog-content *ngIf="isHistoryLoaded && relations.length">
    <h3 class="font-weight-bold">Related Records:</h3>
    <div class="scroll">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let key of relations">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{key}}</mat-panel-title>
                </mat-expansion-panel-header>
                <ul *ngFor="let related of relatedRecords[key]">
                    <li>
                        <a (click)="selectRelatedRecord(related.className, related.id)" class="add-blue">{{related.className}} {{related.id}}</a>
                    </li>
                </ul>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex">
        <select (change)="idInput.value = ''"
                [(ngModel)]="data.model"
                class="form-control mr-2">
            <option *ngFor="let model of models" [ngValue]="model">{{model}}</option>
        </select>
        <input #idInput [(ngModel)]="data.id" class="form-control" placeholder="Object ID">
    </div>
    <button class="btn btn-outline-secondary" mat-dialog-close>
        Done
    </button>
</mat-dialog-actions>
