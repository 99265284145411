import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessor } from '../abstract-value-accessor';

export const CURRENCY_INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CurrencyInputComponent),
    multi: true,
};

@Component({
    selector: 'lf-currency',
    styleUrls: ['./currency-input.scss'],
    templateUrl: './currency-input.component.html',
    providers: [CURRENCY_INPUT_VALUE_ACCESSOR],
})
export class CurrencyInputComponent extends AbstractValueAccessor<number>
{
    @Input() formGroup: FormGroup;
    @Input() controlName: string;

    @Input() currency: string = 'usd';
    @Input() error: boolean = false;
    @Input() min: number;
    @Input() max: number;
    @Input() placeholder: string = '0.00';
    @Input() readonly = false;
    @Input() required = false;
    @Input() showSymbol = false;
    @Input() disabled = false;
    @Input() name = '';
    @Input() negativeAmounts = false;

    @Output() blurred: EventEmitter<any> = new EventEmitter<any>();

    displayAmount: string = '';

    private _numberPipe: DecimalPipe;

    constructor(_numberPipe: DecimalPipe) {
        super();

        this._numberPipe = _numberPipe;
    }

    writeValue(value: number) {
        value = this.constrainAmount(value);

        if (value !== this._value) {
            this._value = value;
            this.displayAmount = this.formatDisplayCurrency(this.value);

            // Control value accessor - makes ngModel validation work.
            this.propagateChange(this._value);
        }
    }

    /**
     * Converts the provided display amount (in dollars for USD) to an integer based unit of measurement (cents for USD).
     * @param {number} actualAmount
     * @returns {number}
     */
    formatDisplayCurrency(actualAmount: number): string {
        if (!Number.isInteger(actualAmount)) {
            return '';
        }

        const currency = this.currency.toLocaleLowerCase();
        switch (currency) {
            case 'usd':
            case 'cad':
                let transform = this._numberPipe.transform(actualAmount / 100, '.2');
                transform = transform.replace(/,/g, '').replace(this.currency.toLocaleUpperCase(), '');

                return transform;
        }

        return actualAmount.toFixed(2);
    }

    /**
     * Sets the control value to the provided display amount (in dollars for USD)
     * to an integer based unit of measurement (cents for USD).
     * @param {string} displayAmount
     */
    setValueFromDisplay(displayAmount: string) {
        displayAmount = displayAmount.replace(/,/g, '');

        // If they supplied a value, and then deleted all int's...
        if (!displayAmount) {
            this.displayAmount = '';
            this.writeValue(null); // Set the value to null when no value is provided
            return; // Return to avoid further processing
        }

        // If we get something stupid like 2.2222, round it off to a cent format.
        const overlargeDecimalLengthRegex = /^[-]?[0-9]*(\.[0-9]{3,})?$/;
        if (displayAmount && overlargeDecimalLengthRegex.test(displayAmount)) {
            displayAmount = this.formatDisplayCurrency(this.normalizeDisplayCurrencyToInteger(Number(displayAmount)));
            this.displayAmount = displayAmount;
        }

        // This presumes an American currency formatting. We should revisit this upon supporting additional currencies.
        // Optional negation operator, 0-n digits prior to decimal.
        // If there is a decimal component, require the decimal character and 1-2 more digits.
        const dollarRegex = /^[-]?[0-9]*(\.[0-9]{1,2})?$/;
        if (displayAmount && dollarRegex.test(displayAmount)) {
            const value = this.normalizeDisplayCurrencyToInteger(Number(displayAmount));
            this.writeValue(value);
        }
    }

    /**
     * Converts the provided display amount (in dollars for USD) to an integer based unit of measurement (cents for USD).
     * @param {number} displayAmount
     * @returns {number}
     */
    normalizeDisplayCurrencyToInteger(displayAmount: number) {
        const currency = this.currency.toLocaleLowerCase();
        switch (currency) {
            case 'usd':
            case 'cad':
                return Math.round(displayAmount * 100);
        }

        return displayAmount;
    }

    /**
     * Force the provided amount to be constrained to the min and max inputs, if set.
     * @param {number} amount
     * @returns {number}
     */
    constrainAmount(amount: number) {
        if (typeof amount !== 'undefined' && amount !== null) {
            if (this.min !== null && this.min > amount) {
                amount = this.min;
            } else if (this.max !== null && this.max < amount) {
                amount = this.max;
            }
        }

        return amount;
    }
}
