export class FormSubmissionStatus
{
    id: number;
    formId: number;
    sortOrder: number;
    title: string;
    class: string;
    color: string;
    bgColor: string;

    submissionsCount: number;
    openSubmissionsCount: number;
    closedSubmissionsCount: number;

    constructor(request: any) {
        this.id = request.id || 0;
        this.formId = request.formId;
        this.sortOrder = request.sortOrder;
        this.class = request.class || 'custom';
        this.title = request.title;
        this.color = request.color;
        this.bgColor = request.color + '40';

        // handle default color (#ffffff) if they never changed it
        if (this.color === '#ffffff' || this.color === '#FFFFFF' || this.color === '#212529') {
            this.color = '#212529';
            this.bgColor = '#ffffff';
        }

        this.submissionsCount = request.submissionsCount || 0;
        this.openSubmissionsCount = request.openSubmissionsCount || 0;
        this.closedSubmissionsCount = request.closedSubmissionsCount || 0;
    }
}
