import { Injectable } from '@angular/core';
import { AppNavItem } from '../models/entities/app-nav-item';
import { OrganizationPreference } from '../models/entities/organization-preference';
import { BehaviorSubject } from 'rxjs';
import { JwtLegFiClaims } from './auth/jwt-legfi-claims.model';
import { LegFiJwtService } from './auth/legfi-jwt.service';
import { Organization } from '../models/entities/organization';
import moment from 'moment-timezone';
import { CloneService } from './clone.service';
import { AppNavigation } from '../config/app-navigation';
import { AppNavigationProperties } from '../models/entities/app-navigation-properties';
import { AppNavigationKeys } from '../enums/app-navigation-keys.enum';
import { Tabs } from '../config/tabs';
import { ViewTab } from '../models/entities/view-tab';
import { UnitDashboardKeys } from '../enums/unit-dashboard-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class AppNavigationService
{
    jwt: JwtLegFiClaims;
    isAdmin = false;
    isOwner = false;
    currentRoute: [path: string, queryParams: { [prop: string]: string | number; }] = [
        '/',
        {},
    ];

    private isInMobileMode = false;
    private hasLaunchpad = false;
    private isFormsEnabled = false;
    private isViolationsEnabled = false;
    private isCalendarModuleEnabled = false;
    private isSurveysModuleEnabled = false;
    private isMessageBoardModuleEnabled = false;
    private isMembershipDirectoryModuleEnabled = false;
    private isPayablesModuleEnabled = false;

    private hasCustomFormsPermissions = false;
    private hasOrganizationSettings = false;
    private hasSurveys = false;

    private readonly sideNavigation: AppNavigationProperties[] = CloneService.clone(AppNavigation.SideNavigation);
    private readonly sideMobileNavigation: AppNavigationProperties[] = CloneService.clone(AppNavigation.SideMobileNavigation);
    private readonly sideAdminNavigation: AppNavigationProperties[] = CloneService.clone(AppNavigation.SideAdminNavigation);
    private readonly unitDashboardTabs: ViewTab[] = CloneService.clone(Tabs.UnitDashboardTabs);
    private readonly unitIdKey = AppNavigation.unitIdKey;

    private readonly hasOrgSettingsDisabled = [
        226155,
    ];

    private navSubject$: BehaviorSubject<AppNavItem[]> = new BehaviorSubject<AppNavItem[]>([]);

    constructor() {
        this.jwt = LegFiJwtService.read();
        this.isAdmin = this.jwt.superUser || this.jwt.admin;
        this.isOwner = !!this.jwt.unitId;
    }

    navigation$() {
        return this.navSubject$.asObservable();
    }

    updateMobileMode(inMobileMode = false) {
        if (this.isInMobileMode !== inMobileMode) {
            this.isInMobileMode = inMobileMode;
            this.refreshNavigation();
        }
    }

    updateLaunchpad(organization: Organization) {
        // only show launchpad for first 30 days after sign-up
        this.hasLaunchpad = moment().add(-30, 'days').isBefore(moment(organization.createdAt));
        if (this.hasLaunchpad) {
            this.refreshNavigation();
        }
    }

    updateWithPreferences(preferences: OrganizationPreference) {
        this.isFormsEnabled = preferences.formsModuleEnabled || false;
        this.isViolationsEnabled = preferences.violationsModuleEnabled || false;
        this.isCalendarModuleEnabled = preferences.calendarModuleEnabled || false;
        this.isSurveysModuleEnabled = preferences.surveysModuleEnabled || false;
        this.isMessageBoardModuleEnabled = preferences.messageBoardModuleEnabled || false;
        this.isMembershipDirectoryModuleEnabled = preferences.membershipDirectoryModuleEnabled || false;
        this.isPayablesModuleEnabled = preferences.payablesModuleEnabled || false;
        this.hasOrganizationSettings = this.hasOrgSettingsDisabled.indexOf(this.jwt.orgId) === -1;

        this.refreshNavigation();
    }

    updateCustomFormsPermissions(hasPermissions = false) {
        this.hasCustomFormsPermissions = this.isAdmin || hasPermissions;
        if (this.hasCustomFormsPermissions) {
            this.refreshNavigation();
        }
    }

    updateSurveys(hasSurveys = false) {
        this.hasSurveys = hasSurveys;
        if (this.hasSurveys) {
            this.refreshNavigation();
        }
    }

    updateActiveRoute(route: string, queryParams?: { [prop: string]: string; }) {
        this.currentRoute[0] = route;
        this.currentRoute[1] = queryParams || {};

        const currentNavigation = this.navSubject$.getValue();
        for (const item of currentNavigation) {
            item.isActiveRoute = this.getIsActiveById(item)();
        }
    }

    resetNavigation(isAdminMenu = false) {
        this.navSubject$.next([
            ...this.getNavItems(isAdminMenu ? this.sideAdminNavigation : this.sideNavigation),
            ...this.getNavItems(this.sideMobileNavigation),
        ]);
    }

    private refreshNavigation() {
        const currentNavigation = this.navSubject$.getValue();
        for (const item of currentNavigation) {
            item.isActiveRoute = this.getIsActiveById(item)();
            item.isVisibleRoute = this.getIsVisibleById(item)();
        }
    }

    private hasQueryParam(param, value = null) {
        if (this.currentRoute[1] === {}) {
            return false;
        }

        if (param in this.currentRoute[1]) {
            return this.currentRoute[1][param] === value;
        }

        return false;
    }

    private isActiveRoute(routeString: string, exact?: boolean): boolean {
        // Strip query params off of search + current path if there aren't any in the search route.
        if (routeString.indexOf('?') !== -1) {
            routeString = routeString.substring(0, routeString.indexOf('?'));
        }
        if (routeString.indexOf(';') !== -1) {
            routeString = routeString.substring(0, routeString.indexOf(';'));
        }

        // Replace any route params with a regex match sequence.
        const routeParamRegex = /\/:[\w_-]+\//g;
        routeString = routeString.replace(routeParamRegex, '/[w_-]+/');
        if (exact) {
            routeString = '^' + routeString + '$';
        }

        // Test current url against our pattern.
        return new RegExp(routeString).test(this.currentRoute[0]);
    }

    private getNavItems(navProperties: AppNavigationProperties[]) {
        const navItems: AppNavItem[] = [];
        for (const property of navProperties) {
            // handle route/queryParams first
            const unitId = this.jwt.unitId ? String(this.jwt.unitId) : '';
            let routeString: string;
            let queryParams = null;
            if (Array.isArray(property.route)) {
                routeString = property.route[0];
                queryParams = property.route[1];
            } else {
                routeString = property.route;
            }
            routeString = routeString.replace(this.unitIdKey, unitId);

            // define nav item
            const item = new AppNavItem({
                id: property.id,
                displayText: property.text,
                iconString: property.icon,
                routeString,
                queryParams,
                order: property.order,
                hasMarginBelow: property.hasMarginBelow,
                externalUrl: property.externalUrl,
            });

            item.isVisibleRoute = this.getIsVisibleById(item)();
            navItems.push(item);
        }

        return navItems.sort((a, b) => a.order - b.order);
    }

    private getIsVisibleById(item: AppNavItem): () => boolean {
        switch (item.id) {
            case AppNavigationKeys.LAUNCHPAD:
                return () => this.isAdmin && this.hasLaunchpad;
            case AppNavigationKeys.ORG_DASHBOARD:
                return () => LegFiJwtService.doesUserHaveModulePermission('dashboard', false);
            case AppNavigationKeys.MEMBER_DASHBOARD:
                return () => !this.isAdmin && !LegFiJwtService.doesUserHaveModulePermission('dashboard', false);
            case AppNavigationKeys.UNIT_LIST:
                return () => LegFiJwtService.doesUserHaveModulePermission('unit', false);
            case AppNavigationKeys.PEOPLE_LIST:
                return () => !this.jwt.hq && LegFiJwtService.doesUserHaveModulePermission('members', false);
            case AppNavigationKeys.INVOICES:
                return () => LegFiJwtService.doesUserHaveModulePermission('charges', false);
            case AppNavigationKeys.PAYMENTS:
                return () => LegFiJwtService.doesUserHaveModulePermission('payments', false);
            case AppNavigationKeys.VENDORS:
                return () => LegFiJwtService.doesUserHaveModulePermission('billpay', false);
            case AppNavigationKeys.PAYABLES:
                return () => this.isPayablesModuleEnabled && (LegFiJwtService.doesUserHaveModulePermission('billpay', false) && LegFiJwtService.featureEnabled('payables.enabled'));
            case AppNavigationKeys.TRANSACTIONS:
                return () => LegFiJwtService.doesUserHaveModulePermission('transactions', false);
            case AppNavigationKeys.BUDGETS:
                return () => LegFiJwtService.doesUserHaveModulePermission('budgets', false)
                        || this.isActiveRoute('/app/chart-of-accounts');
            case AppNavigationKeys.REPORTS:
                return () => LegFiJwtService.doesUserHaveModulePermission('reports', false);
            case AppNavigationKeys.BROADCAST:
                return () => LegFiJwtService.doesUserHaveModulePermission('messaging', false);
            case AppNavigationKeys.MAIL_ROOM:
                return () => LegFiJwtService.doesUserHaveModulePermission('mail-room', false);
            case AppNavigationKeys.OTHER:
                return () => (this.isSurveysModuleEnabled && LegFiJwtService.doesUserHaveModulePermission('surveys', false))
                        || LegFiJwtService.doesUserHaveModulePermission('website-builder', true)
                        || LegFiJwtService.doesUserHaveModulePermission('members', true)
                        || LegFiJwtService.doesUserHaveModulePermission('charges', true)
                        || LegFiJwtService.doesUserHaveModulePermission('communication', false);
            case AppNavigationKeys.SURVEYS:
                return () => !this.isAdmin && this.isSurveysModuleEnabled && this.hasSurveys
                        && !LegFiJwtService.doesUserHaveModulePermission('surveys', false);
            case AppNavigationKeys.MESSAGE_BOARD:
                return () => !this.isAdmin && this.isMessageBoardModuleEnabled;
            case AppNavigationKeys.CALENDAR:
                return () => !this.isAdmin && this.isCalendarModuleEnabled;
            case AppNavigationKeys.MEMBERSHIP_DIRECTORY:
                return () => !this.isAdmin && this.isMembershipDirectoryModuleEnabled && LegFiJwtService.featureEnabled('membership-directory.enabled');
            case AppNavigationKeys.ORG_REQUESTS:
                return () => this.hasCustomFormsPermissions && this.isFormsEnabled;
            case AppNavigationKeys.MEMBER_REQUESTS:
                return () => this.isOwner && !this.hasCustomFormsPermissions && this.isFormsEnabled;
            case AppNavigationKeys.ORG_VIOLATIONS:
                return () => this.isViolationsEnabled && LegFiJwtService.doesUserHaveModulePermission('violation', false);
            case AppNavigationKeys.MEMBER_VIOLATIONS:
                return () => this.isOwner && this.isViolationsEnabled
                        && !LegFiJwtService.doesUserHaveModulePermission('violation', false);
            case AppNavigationKeys.ORG_DOCUMENTS:
                return () => LegFiJwtService.doesUserHaveModulePermission('documents', false);
            case AppNavigationKeys.MEMBER_DOCUMENTS:
                return () => this.isOwner && !LegFiJwtService.doesUserHaveModulePermission('documents', false);
            case AppNavigationKeys.ORG_SETTINGS:
                return () => this.isInMobileMode && this.hasOrganizationSettings
                        && LegFiJwtService.doesUserHaveModulePermission('organization', false);
            case AppNavigationKeys.HELP:
                return () => !LegFiJwtService.doesUserHaveModulePermission(
                        'support',
                        false,
                );
            case AppNavigationKeys.MAKE_PAYMENT:
                return () => this.isInMobileMode && this.isAdmin;
            case AppNavigationKeys.OWNERS_ACCOUNT:
                return () => this.isInMobileMode && !!this.jwt.unitId;
            case AppNavigationKeys.MY_ACCOUNT:
                return () => this.isInMobileMode && !!this.jwt.memberId;
            case AppNavigationKeys.LOG_OUT:
                return () => !!this.isInMobileMode;
            case AppNavigationKeys.MANAGE_PAYMENTS:
            case AppNavigationKeys.MANAGE_ORGANIZATIONS:
            case AppNavigationKeys.LOCKBOX:
            case AppNavigationKeys.BILLING:
            case AppNavigationKeys.PRODUCTS:
            case AppNavigationKeys.PLANS:
            case AppNavigationKeys.FAILED_JOBS:
                return () => !!(this.jwt.superUser || this.jwt.impersonatedBy);
            default:
                return () => false;
        }
    }

    private getIsActiveById(item: AppNavItem): () => boolean {
        switch (item.id) {
            case AppNavigationKeys.MEMBER_DASHBOARD:
                return () => this.isActiveRoute('/app/members', false);
            case AppNavigationKeys.UNIT_LIST:
                return () => this.isActiveRoute('/app/unit');
            case AppNavigationKeys.PEOPLE_LIST:
                return () => this.isActiveRoute('/app/people')
                        || this.isActiveRoute('/app/members', false)
                        || (this.isActiveRoute('/app/owner/dashboard') && LegFiJwtService.doesUserHaveModulePermission('dashboard', false));
            case AppNavigationKeys.MEMBERSHIP_DIRECTORY:
                return () => this.isActiveRoute('/app/member-directory');
            case AppNavigationKeys.INVOICES:
                return () => this.isActiveRoute('/app/charges', false)
                        || this.isActiveRoute('/app/mail/send/invoice');
            case AppNavigationKeys.PAYMENTS:
                return () => this.isActiveRoute('/app/payments');
            case AppNavigationKeys.VENDORS:
                return () => this.isActiveRoute('/app/bill-pay', false)
                        || this.isActiveRoute('/app/vendor', false);
            case AppNavigationKeys.PAYABLES:
                return () => this.isActiveRoute('/app/payables', false);
            case AppNavigationKeys.BROADCAST:
                return () => this.isActiveRoute('/app/broadcast') || this.isActiveRoute('/app/communications/send');
            case AppNavigationKeys.OTHER:
                return () => this.isActiveRoute('/app/other-tools') || this.isActiveRoute('/app/communications/org-log') && LegFiJwtService.doesUserHaveModulePermission('communication', false);
            case AppNavigationKeys.ORG_REQUESTS:
            case AppNavigationKeys.MEMBER_REQUESTS:
                return () => this.isActiveRoute('/app/forms', false)
                        || this.isActiveRoute('/app/form-detail', false) || this.isActiveRoute('/app/form-submission', false)
                        || this.isActiveRoute('/app/form-builder', false) || this.isActiveRoute('/app/new-form', false)
                        || this.isActiveRoute('/app/form-settings') || this.hasQueryParam('tab', this.unitDashboardTabs[UnitDashboardKeys.REQUESTS].name);
            case AppNavigationKeys.MEMBER_VIOLATIONS:
                return () => this.isActiveRoute('/app/violations/member', false) || this.hasQueryParam('tab', this.unitDashboardTabs[UnitDashboardKeys.VIOLATIONS].name);
            case AppNavigationKeys.MEMBER_DOCUMENTS:
                return () => this.isActiveRoute('/app/communications/documents') || this.hasQueryParam('tab', this.unitDashboardTabs[UnitDashboardKeys.DOCUMENTS].name);
            case AppNavigationKeys.HELP:
                return () => false;
            default:
                return () => this.isActiveRoute(item.routeString, false);
        }
    }
}
