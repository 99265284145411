import Moment = moment.Moment;
import { LegFiJwtService } from '../../services/auth/legfi-jwt.service';
import moment from 'moment-timezone';
import { BillPayVendor } from 'app/models/entities/bill-pay-vendor';
import { Membership } from 'app/models/entities/membership';

export class Form1099
{
    id: number;
    organizationId: number;
    type: string;
    status: string;
    statusAsOf: Moment;
    createdAt: Moment;
    updatedAt: Moment;
    deletedAt: Moment;
    transmittedAt: Moment;
    transmittedBy: Membership;
    vendorId: number;
    recipient: any;
    formData: {
        SubmissionManifest: {
            SubmissionId: string
            TaxYear: string,
            IsFederalFiling: boolean,
            IsStateFiling: boolean,
            IsPostal: boolean,
            IsOnlineAccess: boolean,
            IsScheduleFiling: boolean,
            ScheduleFiling: any,
        },
        ReturnHeader: {
            Business: any;
        },
        ReturnData: {
            Recipient: any,
            NECFormData?: any;
            MISCFormData?: any;
        }[],
        StateReconData: {
            AL?: any,
            WV?: any
        }
    };
    rejectionError: any;
    vendor: BillPayVendor;

    constructor(form1099: any) {
        this.id = form1099.id;
        this.organizationId = form1099.organizationId;
        this.type = form1099.type;
        this.status = form1099.status;
        const timezone = LegFiJwtService.getTimezone();
        this.createdAt = form1099.createdAt ? moment.utc(form1099.createdAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone) : null;
        this.updatedAt = form1099.updatedAt ? moment.utc(form1099.updatedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone) : null;
        this.deletedAt = form1099.deletedAt ? moment.utc(form1099.deletedAt, 'YYYY-MM-DD hh:mm:ss').tz(timezone) : null;
        this.statusAsOf = form1099.statusAsOf
                ? moment.utc(form1099.statusAsOf, 'YYYY-MM-DD hh:mm:ss').tz(timezone)
                : null;
        this.transmittedAt = form1099.transmittedAt ? moment.utc(form1099.transmittedAt, 'YYYY-MM-DD hh:mm:ss')
                .tz(timezone) : null;
        this.transmittedBy = form1099.transmittedBy ? new Membership(form1099.transmittedBy) : null;
        this.recipient = form1099.recipient || {};
        this.formData = form1099.formData || {};
        this.vendorId = form1099.vendorId || null;
        this.vendor = form1099.vendor ? new BillPayVendor(form1099.vendor) : null;
        this.rejectionError = form1099.rejectionError || null;
    }
}
