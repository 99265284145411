import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { EchoService } from '../../../services/echo.service';

@Injectable()
export class EchoInterceptor implements HttpInterceptor
{

    constructor(
            private _location: Location,
            private _echoService: EchoService,
            private _router: Router,
    ) {
    }

    public intercept(
            req: HttpRequest<any>,
            next: HttpHandler,
    ): Observable<HttpEvent<any>> {

        const dontAddList = [
            '.plaid.com',
        ];

        for (const toMatch of dontAddList) {
            if (req.url.indexOf(toMatch) !== -1) {
                return next.handle(req);
            }
        }

        const socketId = this._echoService.getSocketId();
        if (socketId !== null) {
            const headers = req.headers.set('X-Socket-ID', socketId);
            const newReq = req.clone({
                headers: headers,
            });

            return next.handle(newReq);
        }
        return next.handle(req);
    }
}
